import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, getDateWithDay } from "../../comman/config";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../components/Loader.js";
import Select from 'react-select';

function DairyIssueList() {
    // Form state
    const today = new Date().toISOString().split("T")[0];
    const [mealDate, setMealDate] = useState(today);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [selectedKits, setSelectedKits] = useState([]);
    const [issuedQty, setIssuedQty] = useState("");
    const [crates, setCrates] = useState("");
    const [remarks, setRemarks] = useState("");
    const [activeIndex, setActiveIndex] = useState(null);

    // Data lists
    const [ingredientList, setIngredientList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [menuItemList, setMenuItemList] = useState([]);
    const [kitList, setKitList] = useState([]);

    // UI states
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        getSessionList();
        loadIngredientListForDate();
    }, [mealDate]);

    useEffect(() => {
        if (selectedIngredient && selectedSession) {
            loadMenuItems();
        } else {
            setMenuItemList([]);
            setSelectedMenuItem(null);
        }
    }, [selectedIngredient, selectedSession, mealDate]);

    useEffect(() => {
        if (selectedMenuItem) {
            loadKitNumbers();
        } else {
            setKitList([]);
            setSelectedKits([]);
        }
    }, [selectedMenuItem, selectedSession, selectedIngredient, mealDate]);

    useEffect(() => {
        if (selectedIngredient && issuedQty) {
            calculateCrates();
        }
    }, [selectedIngredient, issuedQty]);

    const getSessionList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${baseUrl}/comman/list?table=session_master&condition=status=1`,
                { headers: { 'Cache-Control': 'no-cache' } }
            );
            setSessionList(response.data.data);
        } catch (error) {
            console.error("Error fetching sessions:", error);
            toast.error("Failed to load sessions");
        } finally {
            setLoading(false);
        }
    };

    const loadIngredientListForDate = async () => {
        setLoading(true);
        try {
            // Query item_requirement_summary for the selected date and order_status = 3
            const response = await axios.get(
                `${baseUrl}/ingredients/list?limit=all`
            );

            // Filter ingredients for dairy store (store = 2) that have order_status = 3 for the selected date
            const orderResponse = await axios.post(`${baseUrl}/dairy_order/list`, {
                from_date: mealDate,
                to_date: mealDate,
                order_status: "3" // Only ordered ingredients
            });

            // Get ingredient IDs that have been ordered
            const orderedIngredientIds = orderResponse.data.data.map(item => item.ingredient_id);

            // Filter the full ingredient list to only show dairy store ingredients that have been ordered
            const filteredList = response.data.data.filter(
                item => item.status == 1 &&
                    item?.store?.id == 2 &&
                    orderedIngredientIds.includes(item.id)
            );
            setIngredientList(filteredList);
        } catch (error) {
            console.error("Error fetching ingredients:", error);
            toast.error("Failed to load ingredients");
        } finally {
            setLoading(false);
        }
    };

    const loadMenuItems = async () => {
        setLoading(true);
        try {
            // Query item_requirement_detail to get menu items for the selected date, session, and ingredient
            const response = await axios.get(
                `${baseUrl}/dairy_issue/menu_item`,
                {
                    params: {
                        condition: `ird.date = '${mealDate}' AND ird.ingredient_id = '${selectedIngredient.id}' AND ird.session = '${selectedSession.id}'`
                    }
                }
            );

            // Extract unique menu items
            const uniqueMenuItems = [];
            const seenMenuItemIds = new Set();

            response.data.data.forEach(item => {
                if (!seenMenuItemIds.has(item.item_id)) {
                    seenMenuItemIds.add(item.item_id);
                    uniqueMenuItems.push({
                        id: item.menu_item_id,
                        item_name: item.item_name
                    });
                }
            });

            setMenuItemList(uniqueMenuItems);
            console.log("menu item:", menuItemList);
        } catch (error) {
            console.error("Error fetching menu items:", error);
            toast.error("Failed to load menu items");
        } finally {
            setLoading(false);
        }
    };

    const loadKitNumbers = async () => {
        setLoading(true);
        try {
            // Query item_requirement_master to get kit numbers for the selected date, session, and menu item
            const response = await axios.get(
                `${baseUrl}/dairy_issue/menu_item`,
                {
                    params: {
                        condition: `ird.date = '${mealDate}' AND ird.ingredient_id = '${selectedIngredient.id}' AND ird.session = '${selectedSession.id}' AND ird.menu_item_id = '${selectedMenuItem.id}'`
                    }
                }
            );

            // Extract unique kit numbers
            const uniqueKits = [];
            const seenKitNumbers = new Set();
            let issuedQty = 0;
            let remarks = "";

            response.data.data.forEach(item => {
                if (item.kit_no && !seenKitNumbers.has(item.kit_no)) {
                    seenKitNumbers.add(item.kit_no);
                    uniqueKits.push({
                        id: item.kit_no,
                        kit_no: item.kit_no,
                        batch: item.batch
                    });
                }
                if (item.net_issued_qty) {
                    issuedQty = Number(item.net_issued_qty);
                }
                if(item.remarks){
                    remarks = item.remarks
                }
            });

            setKitList(uniqueKits);
            setIssuedQty(issuedQty);
            setRemarks(remarks);
        } catch (error) {
            console.error("Error fetching kit numbers:", error);
            toast.error("Failed to load kit numbers");
        } finally {
            setLoading(false);
        }
    };

    const calculateCrates = () => {
        if (!selectedIngredient || !issuedQty) {
            setCrates("");
            return;
        }

        const qtyPerCrate = selectedIngredient.std_qty_per_crate || 1;
        const calculatedCrates = parseFloat(issuedQty) / qtyPerCrate;
        setCrates(calculatedCrates.toFixed(2));
    };

    // const handleKitChange = (kitId) => {
    //     setSelectedKits(prevSelected => {
    //         if (prevSelected.includes(kitId)) {
    //             return prevSelected.filter(id => id !== kitId);
    //         } else {
    //             return [...prevSelected, kitId];
    //         }
    //     });
    // };

    const handleKitChange = (kit) => {
        setSelectedKits((prevSelected) => {
            const isSelected = prevSelected.some(item => item.kit_no === kit.kit_no);

            if (isSelected) {
                // Remove if already selected
                return prevSelected.filter(item => item.kit_no !== kit.kit_no);
            } else {
                // Add new selection with batch
                return [...prevSelected, { kit_no: kit.kit_no, batch: kit.batch }];
            }
        });
    };

    const validateForm = () => {
        const errors = {};

        if (!mealDate) errors.mealDate = "Meal date is required";
        if (!selectedIngredient) errors.ingredient = "Ingredient is required";
        if (!selectedSession) errors.session = "Session is required";
        if (!issuedQty || issuedQty <= 0) errors.issuedQty = "Valid issued quantity is required";

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error("Please fill all required fields");
            return;
        }

        setLoading(true);
        try {
            // Prepare data for submission
            const issueData = {
                meal_date: mealDate,
                ingredient_id: selectedIngredient.id,
                session_id: selectedSession.id,
                menu_item_id: selectedMenuItem?.id || null,
                kit_numbers: selectedKits,
                issued_qty: issuedQty,
                crates: crates,
                remarks: remarks
            };

            console.log("issuedata:", issueData);

            // Send issue data to the server
            const response = await axios.post(`${baseUrl}/dairy_issue/create`, issueData);

            if (response.data.status) {
                toast.success("Item issued successfully");
                // Reset form
                setSelectedMenuItem(null);
                setSelectedKits([]);
                setIssuedQty("");
                setCrates("");
                setRemarks("");
                setSelectedSession(null);
                setSelectedIngredient(null);
                setMealDate(today);
            } else {
                toast.error(response.data.msg || "Failed to issue item");
            }
        } catch (error) {
            console.error("Error creating issue:", error);
            toast.error("Failed to issue item");
        } finally {
            setLoading(false);
        }
    };

    const handleChangeMultiSelect = (selectedOptions) => {
        // Convert the selected options back to your kit objects
        const selected = selectedOptions ?
            kitList.filter(kit => selectedOptions.some(option => option.value === kit.id)) :
            [];
        setSelectedKits(selected);
    };

    const handleDropdownClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle dropdown state
    };

    return (
        <div className="p-4">
            {loading && <Loader />}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
                    Dairy Store Issue
                </h1>
                <Toaster position="top-center" reverseOrder={false} />
            </div>

            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-6">
                        {/* Meal Date */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="mealDate">
                                    Meal Date *
                                </label>
                                <div className="flex-1">
                                    <input
                                        id="mealDate"
                                        type="date"
                                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.mealDate ? 'border-red-500' : ''}`}
                                        value={mealDate}
                                        onChange={(e) => setMealDate(e.target.value)}

                                    />
                                    {formErrors.mealDate && <p className="text-red-500 text-xs italic">{formErrors.mealDate}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Ingredient Name */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2 relative">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="ingredient">
                                    Ingredient Name *
                                </label>
                                <div className="relative flex-1">
                                    <Select
                                        id="ingredient"
                                        className={`${formErrors.ingredient ? "border-red-500" : ""}`}
                                        classNamePrefix="select"
                                        value={selectedIngredient ? {
                                            value: selectedIngredient.id,
                                            label: selectedIngredient.ingredient
                                        } : null}
                                        name="ingredient"
                                        onChange={(selected) => {
                                            setSelectedIngredient(
                                                selected ?
                                                    ingredientList.find(item => item.id === selected.value) :
                                                    null
                                            );
                                        }}
                                        options={ingredientList.map(item => ({
                                            value: item.id,
                                            label: item.ingredient
                                        }))}
                                        placeholder="Select Ingredient"
                                        onMenuOpen={() => handleDropdownClick(0)}
                                        onMenuClose={() => setActiveIndex(null)}

                                    />
                                    {formErrors.ingredient && <p className="text-red-500 text-xs italic">{formErrors.ingredient}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Session */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2 relative">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="session">
                                    Session *
                                </label>
                                <div className="relative flex-1">
                                    <Select
                                        id="session"
                                        className={`${formErrors.session ? 'border-red-500' : ''}`}
                                        classNamePrefix="select"
                                        value={selectedSession ? {
                                            value: selectedSession.id,
                                            label: selectedSession.name
                                        } : null}
                                        name="session"
                                        onChange={(selected) => {
                                            setSelectedSession(
                                                selected ?
                                                    sessionList.find(item => item.id === selected.value) :
                                                    null
                                            );
                                        }}
                                        options={sessionList.map(item => ({
                                            value: item.id,
                                            label: item.name
                                        }))}
                                        placeholder="Select Session"
                                        onMenuOpen={() => handleDropdownClick(1)}
                                        onMenuClose={() => setActiveIndex(null)}
                                    />
                                    {formErrors.session && <p className="text-red-500 text-xs italic">{formErrors.session}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Menu Item */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2 relative">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="menuItem">
                                    Menu Item
                                </label>
                                <div className="relative flex-1">
                                    <Select
                                        id="menuItem"
                                        className=""
                                        classNamePrefix="select"
                                        value={selectedMenuItem ? {
                                            value: selectedMenuItem.id,
                                            label: selectedMenuItem.item_name
                                        } : null}
                                        name="menuItem"
                                        onChange={(selected) => {
                                            setSelectedMenuItem(
                                                selected ?
                                                    menuItemList.find(item => item.id === selected.value) :
                                                    null
                                            );
                                        }}
                                        options={menuItemList.map(item => ({
                                            value: item.id,
                                            label: item.item_name
                                        }))}
                                        placeholder="Select Menu Item"
                                        onMenuOpen={() => handleDropdownClick(2)}
                                        onMenuClose={() => setActiveIndex(null)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Kit Selection */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2 relative">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="kitNumbers">
                                    Kit No.
                                </label>
                                <div className="relative flex-1">
                                    <Select
                                        id="kitNumbers"
                                        isMulti
                                        className={`shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.kitNumbers ? 'border-red-500' : ''}`}
                                        classNamePrefix="select"
                                        value={selectedKits.map(kit => ({
                                            value: kit.id,
                                            label: kit.kit_no
                                        }))}
                                        name="kitNumbers"
                                        onChange={(selectedOptions) => {
                                            const selected = selectedOptions ?
                                                kitList.filter(kit => selectedOptions.some(option => option.value === kit.id)) :
                                                [];
                                            setSelectedKits(selected);
                                        }}
                                        options={kitList.length > 0 ?
                                            kitList.map(kit => ({
                                                value: kit.id,
                                                label: kit.kit_no
                                            })) :
                                            []
                                        }
                                        placeholder="Select Kit Numbers"
                                        filterOption={(option, inputValue) => {
                                            return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                        }}
                                        noOptionsMessage={() => "No kit numbers available"}
                                    />
                                    {formErrors.kitNumbers && <p className="text-red-500 text-xs italic">{formErrors.kitNumbers}</p>}
                                </div>
                            </div>
                        </div>

                        {/* Ltrs./Kgs. and Crates */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="issuedQty">
                                    Issued Qty. *
                                </label>
                                <div className="flex-1 flex gap-4">
                                    <div className="flex-1">
                                        <input
                                            id="issuedQty"
                                            type="number"
                                            // step="0.01"
                                            placeholder="Ltrs./Kgs."
                                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.issuedQty ? 'border-red-500' : ''}`}
                                            value={issuedQty}
                                            onChange={(e) => setIssuedQty(e.target.value)}

                                        />
                                        {formErrors.issuedQty && <p className="text-red-500 text-xs italic">{formErrors.issuedQty}</p>}
                                    </div>
                                    <div className="flex-1">
                                        <input
                                            id="crates"
                                            type="text"
                                            placeholder="Crates"
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                                            value={crates}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Remarks */}
                        <div className="flex flex-col">
                            <div className="flex items-center mb-2">
                                <label className="block text-gray-700 font-bold w-40" htmlFor="remarks">
                                    Remarks
                                </label>
                                <div className="flex-1">
                                    <textarea
                                        id="remarks"
                                        placeholder="To enter (if any)"
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="flex items-center justify-center mt-4">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded focus:outline-none focus:shadow-outline"
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DairyIssueList;