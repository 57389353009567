import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import { baseUrl, formatDate } from "../../comman/config";
import Loader from '../../components/Loader';
import { IngredientFilter } from '../../components/Filter/IngredientFilter';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import gujaratiFont from '../../resources/NotoSansGujarati';

function IngredientsReport() {
    const [ingredientReportData, setIngredientReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [session, setSession] = useState([]);
    const [item, setItem] = useState([]);
    const [event, setEvent] = useState({});
    const [ingredients, setIngredients] = useState([]);
    const [store, setStore] = useState([]);
    const [category, setCategory] = useState([]);

    // State for modal
    const [showModal, setShowModal] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [ingredientDetails, setIngredientDetails] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(false);

    useEffect(() => {
        if (fromDate && toDate) {
            getIngredientsReport();
        }
    }, [fromDate, toDate, session, item, event, ingredients, store, category]);

    const getIngredientsReport = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/reports/ingredients_total_report`, {
                params: {
                    fromDate,
                    toDate,
                    session: JSON.stringify(session),
                    item: JSON.stringify(item),
                    event: JSON.stringify(event),
                    ingredients: JSON.stringify(ingredients),
                    store: JSON.stringify(store),
                    category: JSON.stringify(category),
                }
            });
            setIngredientReportData(response.data.data);
        } catch (error) {
            console.error("Error fetching ingredient report:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (fromDate, toDate, session, item, event, ingredients, store, category) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setSession(session);
        setItem(item);
        setEvent(event);
        setIngredients(ingredients);
        setStore(store);
        setCategory(category);
    };
    const handleExport = () => {
        if (!ingredientReportData || ingredientReportData.length === 0) {
            console.log('No data to export');
            return;
        }
    
        // Generate title
        const eventName = event ? event.label : "Event";
        const formattedTitle = `${eventName} General Kitchen Menu \nShibir Dates : ${fromDate ? formatDate(fromDate) : ''} - ${toDate ? formatDate(toDate) : ''}`;
    
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([]);
    
        // Add title at the top
        XLSX.utils.sheet_add_aoa(worksheet, [[formattedTitle]], { origin: "A1" });
    
        // Column headers
        const headers = ["Ingredient Name", "Ingredient Category", "Store", "Unit", "Total Qty"];
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A3" });
    
        // Populate data
        let rowIndex = 4;
    
        ingredientReportData.forEach(item => {
            const rowData = [
                item.eng_name ? `${item.ingredient_name} (${item.eng_name})` : item.ingredient_name,
                item.ingredient_category_name,
                item.store_name,
                item.unit,
                item.total_planned_qty.toFixed(3)
            ];
    
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` });
            rowIndex++;
        });
    
        // Set column widths
        worksheet['!cols'] = [
            { wch: 30 }, // Ingredient Name
            { wch: 25 }, // Ingredient Category
            { wch: 20 }, // Store
            { wch: 10 }, // Unit
            { wch: 15 }  // Total Qty
        ];
    
        // Merge title row across columns
        worksheet["!merges"] = [{
            s: { r: 0, c: 0 },
            e: { r: 0, c: headers.length - 1 }
        }];
    
        // Set row heights
        worksheet['!rows'] = [
            { hpt: 30 }, // Title
            , // Empty row
            { hpt: 20 }, // Header
            ...Array(rowIndex - 3).fill({ hpt: 25 }) // Data rows
        ];
    
        // Enable text wrapping for all data cells
        for (let row = 4; row < rowIndex; row++) {
            for (let col = 0; col < headers.length; col++) {
                const cellRef = XLSX.utils.encode_cell({ r: row - 1, c: col });
                if (worksheet[cellRef]) {
                    worksheet[cellRef].s = { alignment: { wrapText: true, vertical: 'top' } };
                }
            }
        }
    
        // Generate and download file
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ingredients Report');
        XLSX.writeFile(workbook, `${eventName}_Ingredients_Report.xlsx`);
    };
     
    const handleExportPdf = () => {
        if (!ingredientReportData || ingredientReportData.length === 0) {
            console.log('No data to export');
            return;
        }

        const eventName = event ? event.label : "Event";
        const eventTitle = `${eventName} General Kitchen Menu  `;
        const eventSubTitle = `Shibir Dates : ${fromDate ? formatDate(fromDate) : ''} - ${toDate ? formatDate(toDate) : ''}`;


        const doc = new jsPDF();
        doc.addFont(gujaratiFont, 'Gujarati', 'normal');
        doc.setFont('Gujarati');

        // Add title to PDF
        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.text(eventTitle, pageWidth / 2, 15, { align: "center" });
        doc.text(eventSubTitle, pageWidth / 2, 25, { align: "center" });

        // Prepare data for table
        const tableColumn = ["Ingredient Name", "Ingredient Category", "Store", "Unit", "Total Qty"];
        const tableRows = ingredientReportData.map(item => {
            const ingredientName = item.eng_name ?
                `${item.ingredient_name} (${item.eng_name})` :
                item.ingredient_name;

            return [
                ingredientName,
                item.ingredient_category_name,
                item.store_name,
                item.unit,
                item.total_planned_qty.toFixed(3)
            ];
        });

        // Add table to PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            theme: 'grid',
            styles: {
                fontSize: 8,
                font: 'Gujarati'
            },
            headStyles: { fillColor: [66, 139, 202] }
        });

        // Save PDF
        doc.save(`${eventName}_Ingredients_Report.pdf`);
    };

    // Function to get ingredient usage details
    const getIngredientDetails = async (ingredientId) => {
        setLoadingDetails(true);
        try {
            const response = await axios.get(`${baseUrl}/reports/ingredient_usage_details`, {
                params: {
                    ingredientId,
                    fromDate,
                    toDate,
                    session: JSON.stringify(session),
                    item: JSON.stringify(item),
                    event: JSON.stringify(event)
                }
            });
            setIngredientDetails(response.data.data);
        } catch (error) {
            console.error("Error fetching ingredient details:", error);
            setIngredientDetails([]);
        } finally {
            setLoadingDetails(false);
        }
    };

    // Handle click on Total Qty cell
    const handleTotalQtyClick = (ingredient) => {
        setSelectedIngredient(ingredient);
        getIngredientDetails(ingredient.ingredient_id);
        setShowModal(true);
    };

    // Close modal
    const closeModal = () => {
        setShowModal(false);
        setSelectedIngredient(null);
        setIngredientDetails([]);
    };

    // Export modal details to Excel
    const handleExportModalDetailsExcel = () => {
        if (!selectedIngredient || !ingredientDetails.length) return;
        const eventName = event ? event.label : "Event";

        // Prepare data for export
        const header = ["Date", "Session", "Menu Item", "Quantity"];

        const data = ingredientDetails.map(detail => {
            const date = new Date(detail.date);
            const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });
            return [
                `${formatDate(detail.date)}, ${weekday}`,
                detail.session_name,
                detail.menu_item_name,
                `${Number(detail.quantity).toFixed(3)} ${selectedIngredient.unit}`
            ];
        });

        // Add total row
        data.push([
            "Total",
            "",
            "",
            `${selectedIngredient.total_planned_qty.toFixed(3)} ${selectedIngredient.unit}`
        ]);

        const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ingredient Detail Report");

        // Generate filename with ingredient name
        const ingredientName = selectedIngredient.eng_name 
            ? `${selectedIngredient.ingredient_name}_${selectedIngredient.eng_name}`
            : selectedIngredient.ingredient_name;
        
        const fileName = `${eventName}_${ingredientName }_Detail_Report.xlsx`;
        XLSX.writeFile(workbook, fileName);
    };

    // Export modal details to PDF
    const handleExportModalDetailsPdf = () => {
        if (!selectedIngredient || !ingredientDetails.length) return;
        const eventName = event ? event.label : "Event";

        const doc = new jsPDF();
        doc.addFont(gujaratiFont, 'Gujarati', 'normal');
        doc.setFont('Gujarati');

        // Add title to PDF
        doc.setFontSize(16);
        const ingredientName = selectedIngredient.eng_name 
            ? `${selectedIngredient.ingredient_name} (${selectedIngredient.eng_name})`
            : selectedIngredient.ingredient_name;
        
        doc.text(`Ingredient Detail Report: ${ingredientName}`, 14, 15);

        // Add date range to PDF
        doc.setFontSize(10);
        doc.text(`Date : ${fromDate ? formatDate(fromDate) : ''} to ${toDate ? formatDate(toDate) : ''}`, 14, 25);

        // Prepare data for table
        const tableColumn = ["Date", "Session", "Menu Item", "Quantity"];
        const tableRows = ingredientDetails.map(detail => {
            const date = new Date(detail.date);
            const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });
            return [
                `${formatDate(detail.date)}, ${weekday}`,
                detail.session_name,
                detail.menu_item_name,
                `${Number(detail.quantity).toFixed(3)} ${selectedIngredient.unit}`
            ];
        });

        // Add total row
        tableRows.push([
            "Total",
            "",
            "",
            `${selectedIngredient.total_planned_qty.toFixed(3)} ${selectedIngredient.unit}`
        ]);

        // Add table to PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            theme: 'grid',
            styles: {
                fontSize: 8,
                font: 'Gujarati'
            },
            headStyles: { fillColor: [66, 139, 202] }
        });

        // Save PDF
        doc.save(`${eventName}_${ingredientName}_Detail_Report.pdf`);
    };

    return (
        <div className="p-4">
            {loading && <Loader />}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-2xl font-bold">Ingredient Estimation Master Report</h1>
                <div>
                    <Link to="/">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>
            <div className="relative shadow-md sm:rounded-lg">
                <IngredientFilter
                    handleFilterChange={handleFilterChange}
                    handleExport={handleExport}
                    handleExportPdf={handleExportPdf}
                />
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3 border border-black text-center">Ingredient Name</th>
                                <th scope="col" className="px-4 py-3 border border-black text-center">Ingredient Category</th>
                                <th scope="col" className="px-4 py-3 border border-black text-center">Store</th>
                                <th scope="col" className="px-4 py-3 border border-black text-center">Unit</th>
                                <th scope="col" className="px-4 py-3 border border-black text-center">Total Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ingredientReportData.length > 0 ? (
                                [...ingredientReportData]
                                .sort((a, b) => a.eng_name.localeCompare(b.eng_name))
                                .map((item, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-4 py-3 border border-black text-center">
                                            {item.ingredient_name} {item.eng_name ? `(${item.eng_name})` : ''}
                                        </td>
                                        <td className="px-4 py-3 border border-black text-center">{item.ingredient_category_name}</td>
                                        <td className="px-4 py-3 border border-black text-center">{item.store_name}</td>
                                        <td className="px-4 py-3 border border-black text-center">{item.unit}</td>
                                        <td
                                            className="px-4 py-3 border border-black text-center cursor-pointer hover:bg-blue-100 hover:text-blue-700 underline"
                                            onClick={() => handleTotalQtyClick(item)}
                                        >
                                            {item.total_planned_qty.toFixed(3)}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="px-4 py-3 text-center border border-black">
                                        No data available. Please adjust your filter criteria.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Details Modal */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg w-4/5 max-w-4xl max-h-screen overflow-hidden">
                        <div className="p-4 border-b flex justify-between items-center">
                            <h3 className="text-lg font-semibold">
                                Ingredient: "{selectedIngredient?.ingredient_name}"
                                {selectedIngredient?.eng_name ? ` (${selectedIngredient.eng_name})` : ''}
                            </h3>
                            <button
                                onClick={closeModal}
                                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="p-4 max-h-[calc(100vh-10rem)] overflow-y-auto">
                            {loadingDetails ? (
                                <div className="flex justify-center items-center p-8">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                </div>
                            ) : ingredientDetails.length > 0 ? (
                                <table className="w-full text-sm text-left">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th className="px-4 py-2 border">Date</th>
                                            <th className="px-4 py-2 border">Session</th>
                                            <th className="px-4 py-2 border">Menu Item</th>
                                            <th className="px-4 py-2 border">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ingredientDetails.map((detail, idx) => (
                                            <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                                                <td className="px-4 py-2 border">
                                                    {formatDate(detail.date)}
                                                </td>
                                                <td className="px-4 py-2 border">
                                                    {detail.session_name}
                                                </td>
                                                <td className="px-4 py-2 border">
                                                    {detail.menu_item_name}
                                                </td>
                                                <td className="px-4 py-2 border text-right">
                                                    {Number(detail.quantity).toFixed(3)} {selectedIngredient?.unit}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="bg-gray-100 font-semibold">
                                            <td colSpan="3" className="px-4 py-2 border text-right">
                                                Total:
                                            </td>
                                            <td className="px-4 py-2 border text-right">
                                                {selectedIngredient?.total_planned_qty.toFixed(3)} {selectedIngredient?.unit}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center py-8">
                                    No detailed usage data available for this ingredient.
                                </div>
                            )}
                        </div>
                        <div className="p-4 border-t flex justify-end gap-2">
                            {ingredientDetails.length > 0 && (
                                <>
                                    <button
                                        onClick={handleExportModalDetailsExcel}
                                        className="px-4 py-2 bg-blue-400 text-white rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    >
                                        Export Excel
                                    </button>
                                    <button
                                        onClick={handleExportModalDetailsPdf}
                                        className="px-4 py-2 bg-blue-400 text-white rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    >
                                        Export PDF
                                    </button>
                                </>
                            )}
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default IngredientsReport;