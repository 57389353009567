import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { baseUrl } from '../../comman/config';

const IngredientFilter = ({ handleFilterChange, handleExport, handleExportPdf }) => {
    const today = new Date().toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [session, setSession] = useState([]);
    const [item, setItem] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [filter, setFilter] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [event, setEvent] = useState({});
    const [ingredientList, setIngredientList] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [store, setStore] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState([{ value: 'all', label: 'All' }]);

    useEffect(() => {
        getSessionList();
        getItemList();
        getEventList();
        getStoreList();
        getIngredientList();
        getIngredientCategories();
    }, []);

    const getSessionList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`);
        const sessionList = response.data.data;
        setSessionList(sessionList);
        setSession([{ value: 'all', label: 'All' }]);
    }

    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        const itemList = response.data.data;
        setItemList(itemList);
    }

    const getIngredientList = async () => {
        const response = await axios.get(`${baseUrl}/ingredients/basic?condition=status=1`);
        const list = response.data.data;
        setIngredientList(list);
    };

    const getIngredientCategories = async () => {
        const response = await axios.get(`${baseUrl}/ingredients/category/list?condition=status=1`);
        const list = response.data.data;
        setCategoryList(list);
    };

    const getStoreList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=store&condition=status=1`);
        const storeData = response.data.data;
        setStoreList(storeData);
        setStore([{ value: 1, label: 'Main Store' }]);
    }

    const getEventList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=event_master&condition=status=1`);
        const data = response.data.data;
        const newObject = { id: 'all', event_name: 'All' };
        data.unshift(newObject);
        setEventList(data);
        setEvent({ value: 'all', label: 'All' });
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === "from_date") {
            setFromDate(value);
        } else if (name === "to_date") {
            setToDate(value);
        }
    };

    const handleSelectChange = (data, option) => {
        if (option.name === 'session') {
            // Handle "All" session logic
            if (data.some(d => d.value === 'all')) {
                setSession([{ value: 'all', label: 'All' }]);
            } else {
                setSession(data);
            }
        } else if (option.name === 'store') {
            // Handle "All" store logic
            if (data.some(d => d.value === 'all')) {
                setStore([{ value: 'all', label: 'All' }]);
            } else {
                setStore(data);
            }
        } else if (option.name === 'category') {
            // Handle "All" category logic
            if (data.some(d => d.value === 'all')) {
                setCategory([{ value: 'all', label: 'All' }]);
            } else {
                setCategory(data);
            }
        }
    };

    const defaultValues = {
        sessions: sessionList
            .filter((item) => item.id === 1 || item.id === 2)
            .map((item) => ({
                value: item.id,
                label: item.name,
            }))
    };

    const handleClear = () => {
        handleFilterChange(today, today);
        setSession([{ value: 'all', label: 'All' }]);
        setItem([]);
        setIngredients([]);
        setStore([{ value: 1, label: 'Main Store' }]);
        setCategory([{ value: 'all', label: 'All' }]);
        setFromDate(today);
        setToDate(today);
        setEvent({ value: 'all', label: 'All' });
        setFilter(false);
    }

    const handleChangeMultiSelect = (selected, name) => {
        if (name === 'item') {
            setItem(selected);
        } else if (name === 'ingredient') {
            setIngredients(selected);
        }
    };

    const handelEventChange = (selectedEvent) => {
        const data = eventList.filter(i => i.id == selectedEvent.value);
        setEvent({ value: data[0].id, label: data[0].event_name });

        // Only update dates and apply filter if it's a real event (not "All")
        if (data[0].id !== 'all' && data[0].start_date && data[0].end_date) {
            setFromDate(data[0].start_date);
            setToDate(data[0].end_date);

            const processedSession = session.some(s => s.value === 'all')
                ? sessionList.map(item => ({ value: item.id, label: item.name }))
                : session;

            const processedStore = store.some(s => s.value === 'all')
                ? storeList.map(item => ({ value: item.id, label: item.name }))
                : store;

            const processedCategory = category.some(c => c.value === 'all')
                ? categoryList.map(cat => ({ value: cat.id, label: cat.name }))
                : category;

            handleFilterChange(data[0].start_date, data[0].end_date, processedSession, item, { value: data[0].id, label: data[0].event_name }, ingredients, processedStore, processedCategory);
            setFilter(true);
        } else if (data[0].id === 'all') {
            // Reset dates but don't apply filter
            setCategory([]);
            setSession([]);
            setItem([]);
            setIngredients([]);
            setStore([]);
            handleFilterChange("2024-01-01", today, session, item, { value: 'all', label: 'All' }, ingredients, store, category);
        }
    }

    const handleSubmit = () => {

        const processedSession = session.some(s => s.value === 'all')
            ? sessionList.map(item => ({ value: item.id, label: item.name }))
            : session;

        const processedStore = store.some(s => s.value === 'all')
            ? storeList.map(item => ({ value: item.id, label: item.name }))
            : store;

        const processedCategory = category.some(c => c.value === 'all')
            ? categoryList.map(cat => ({ value: cat.id, label: cat.name }))
            : category;

        handleFilterChange(fromDate, toDate, processedSession, item, event, ingredients, processedStore, processedCategory);
        setFilter(true);
    };

    const clickExport = () => {
        handleExport();
    }

    const clickExportPDF = () => {
        handleExportPdf();
    }

    return (
        <div className="flex flex-wrap items-center pb-4 gap-4">
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Event List</label>
                <Select
                    classNamePrefix="select"
                    className='basic-single w-48'
                    value={event}
                    name="event_name"
                    onChange={(selectedOptions) => handelEventChange(selectedOptions)}
                    options={eventList.map(item => ({
                        value: item.id,
                        label: item.event_name
                    }))}
                    placeholder="Select"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">From Date</label>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    name="from_date"
                    onChange={handleDateChange}
                    value={fromDate}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">To Date</label>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    name="to_date"
                    onChange={handleDateChange}
                    value={toDate}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Select Sessions</label>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="w-48"
                    value={session}
                    name="session"
                    onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'session' })}
                    options={[
                        { value: 'all', label: 'All' },
                        ...sessionList.map(item => ({
                            value: item.id,
                            label: item.name
                        }))
                    ]}
                    placeholder="Select"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Item List</label>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="basic-multi-select w-72"
                    value={item}
                    name="item"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'item')}
                    options={itemList.map(item => ({
                        value: item.id,
                        label: `${item.item_name} ${item.eng_name ? `(${item.eng_name})` : ''}`
                    }))}
                    placeholder="Select"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Ingredient List</label>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="basic-multi-select w-72"
                    value={ingredients}
                    name="ingredient"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'ingredient')}
                    options={ingredientList.map(ingredient => ({
                        value: ingredient.id,
                        label: `${ingredient.ingredient} ${ingredient.eng_name ? `(${ingredient.eng_name})` : ''}`
                    }))}
                    placeholder="Select"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Ingredient Category</label>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="w-48"
                    value={category}
                    name="category"
                    onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'category' })}
                    options={[
                        { value: 'all', label: 'All' },
                        ...categoryList.map(cat => ({
                            value: cat.id,
                            label: `${cat.name} ${cat.eng_name ? `(${cat.eng_name})` : ''}`
                        }))
                    ]}
                    placeholder="Select"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Select Store</label>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="w-48"
                    value={store}
                    name="store"
                    onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'store' })}
                    options={[
                        { value: 'all', label: 'All' },
                        ...storeList.map(store => ({
                            value: store.id,
                            label: store.name
                        }))
                    ]}
                    placeholder="Select"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button>
                {filter &&
                    <button
                        onClick={handleClear}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                    >
                        Clear
                    </button>
                }
                <button
                    onClick={clickExport}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Export Excel
                </button>
                <button
                    onClick={clickExportPDF}
                    className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Export PDF
                </button>
            </div>
        </div>
    );
    
};

export { IngredientFilter };
