import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, getDateWithDay, requirementStatusList } from "../../comman/config";
import { DairyRequirementFilter } from "../../components/Filter/DairyRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import IngredientBatchModel from "../../components/IngredientBatchModel.js";
import OrderIngredientModel from "../../components/DairyOrderModal.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";
import Loader from "../../components/Loader.js";

function DairyOrderList() {
    const today = new Date().toISOString().split("T")[0];
    const [ingredientOrderList, setIngredientOrderList] = useState([]);
    const [orderStatus, setOrderStatus] = useState({});
    const [orderId, setOrderId] = useState({});
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [ingredient, setIngredient] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [orderIngedientList, setOrderIngredientList] = useState(null);
    const [itemIngedientList, setItemIngredientList] = useState(null);
    const [methodList, setMethodList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [noRecordsFound, setNoRecordsFound] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null)

    useEffect(() => {
        getMethodList()
    }, []);

    useEffect(() => {
        getDairyIngredientOrderList();
    }, [fromDate, toDate, ingredient, orderStatus, orderId])

    const handelFilterChange = async (from_date, to_date, order_status, order_id, ingredient) => {
        setFromDate(from_date);
        setToDate(to_date);
        setIngredient(ingredient);
        setOrderStatus(order_status);
        setOrderId(order_id);
    };

    const getMethodList = async () => {
        const response = await axios.get(
            `${baseUrl}/comman/list?table=method_master&condition=status=1`
        );
        setMethodList(response.data.data);
    };

    const getDairyIngredientOrderList = async () => {
        setLoading(true)
        setNoRecordsFound(false)
        const response = await axios.post(`${baseUrl}/dairy_order/list`,
            {
                from_date: fromDate,
                to_date: toDate,
                ingredient: ingredient?.value,
                order_status: orderStatus?.value,
                order_id: orderId?.value
            }
        );

        setIngredientOrderList(response.data.data);
        setNoRecordsFound(response.data.data.length === 0);
        setLoading(false)
    }

    const displayOrderDetails = async (e, item) => {
        e.preventDefault();
        setLoading(true)
        const response = await axios.get(`${baseUrl}/dairy_order/order_details`, {
            params: {
                order_id: item.order_id 
            }
        });
        setOrderDetails(response.data.data);
        console.log("ordeer details:", orderDetails)
        setLoading(false)
        setShowOrderModal(true);
        setNoRecordsFound(response.data.data.length === 0);
    }

    const handleSubmit = async () => {
        if (selectedRows.length == 0) {
            alert("Please select at least one row");
            setSelectedRows([]);
            return;
        }

        if (selectedRows.some(item => item.order_qty === "" || item.order_qty === null)) {
            alert("One or more items have an empty order qty value.");
            setSelectedRows([]);
            return;
        }

        if (selectedRows.length > 0) {
            setOrderIngredientList(selectedRows);
            try {
                if (window.confirm(`Do you want to place the order for these ingredients?`)) {
                    const response = await axios.post(`${baseUrl}/dairy_order/place_order`,
                        { ingredientsList: selectedRows });
                    const msg = response.data.msg;
                    toast.success(msg);

                    getDairyIngredientOrderList();
                }
            } catch (error) {
                toast.error("Failed to add item. Please try again.");
            }
            setSelectedRows([]);
        }
    };

    const handleSave = async () => {
        // if (selectedRows.length == 0) {
        //     alert("Please select at least one row");
        //     setSelectedRows([]);
        //     return;
        // }

        // if (selectedRows.some(item => item.order_qty === "")) {
        //     alert("One or more items have an empty order qty value.");
        //     setSelectedRows([]);
        //     return;
        // }

        // if (selectedRows.length > 0) {

            try {
                const response = await axios.post(`${baseUrl}/dairy_order/save`,
                    {
                        ingredientsList: ingredientOrderList
                    });
                toast.success("Data save successfully.");
                getDairyIngredientOrderList();
            } catch (error) {
                toast.error("Failed to add item. Please try again.");
            }
        // }
    }

    const handleInputChange = (index) => (e) => {
        const { name, value } = e.target;

        setIngredientOrderList((prevFormData) => {
            const updatedFormData = [...prevFormData];
            const currentItem = updatedFormData[index];
            const stdQtyPerCrate = currentItem.std_qty_per_crate;

            const updates = {};

            if (name === 'available_qty') {
                // Update available stock
                updates.available_qty = value;
                // Recalculate required qty based on new available stock
                updates.required_qty = Math.max(currentItem.total_required_qty - value, 0);
            }

            if (name === 'liters_kgs') {
                // Update liters/kgs
                updates.liters_kgs = value;
                // Calculate crates based on liters/kgs and qty/crate
                updates.crates_qty = value ? parseFloat((value / stdQtyPerCrate).toFixed(2)) : '';
                // Set order qty same as liters/kgs
                updates.order_qty = value;
            }

            if (name === 'crates_qty') {
                // Update crates
                updates.crates_qty = value;
                // Calculate liters/kgs based on crates and qty/crate
                updates.liters_kgs = value ? value * stdQtyPerCrate : '';
                // Set order qty based on liters/kgs
                updates.order_qty = updates.liters_kgs;
            }

            updatedFormData[index] = {
                ...currentItem,
                ...updates
            };

            return updatedFormData;
        });
    };

    const handleToggleSelect = (index) => {
        const updatedSelectedRows = selectedRows.includes(ingredientOrderList[index])
            ? selectedRows.filter(item => item !== ingredientOrderList[index]) // Deselect
            : [...selectedRows, ingredientOrderList[index]]; // Select

        setSelectedRows(updatedSelectedRows);
    };

    const handleSwitchSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(ingredientOrderList.filter((item) => item.order_status == 1)); // Select all rows
        }
        setSelectAll(!selectAll);
    };

    const displayIngredientDetail = async (e, item) => {
        e.preventDefault();
        setLoading(true)
        const condition = `ird.date = '${item.date}' AND ird.ingredient_id = '${item.ingredient_id}' AND irm.requirement_status = 2`;
        const RecipeDbData = await axios.get(`${baseUrl}/order/ingredient_list?condition=${condition}`);
        const ingredientsList = RecipeDbData.data.data;
        console.log("ingredients list:",ingredientsList)

        setItemIngredientList(ingredientsList);
        setLoading(false)
        setShowModal(true);
    }

    const clearItem = () => {
        setSelectedRows([]);
        setItemIngredientList(null);
        setOrderIngredientList(null);
        getDairyIngredientOrderList();
    };

    return (
        <div className="p-4">
            {loading && <Loader />}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
                    Dairy Store Order
                </h1>

                <Toaster position="top-center" reverseOrder={false} />
            </div>
            <div className="relative shadow-md sm:rounded-lg">
                <DairyRequirementFilter handleFilterChange={handelFilterChange} page="order" />
            </div>
            {noRecordsFound && <NoRecordsFound />}
            {ingredientOrderList.length > 0 && <div className="overflow-x-auto">
                <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">
                                <input
                                    type="checkbox"
                                    className="newrow checkboxAll"
                                    onChange={handleSwitchSelectAll}
                                    checked={selectAll}
                                />
                            </th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Ingredient</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Meal Date</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Gross Req. Qty</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Available Stock</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Required Qty</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" colSpan="3">Order Qty</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Order Status</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Order Id</th>
                        </tr>
                        <tr>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Liters/Kgs</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Crates</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Qty/Crate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ingredientOrderList.map((item, index) => (
                            <tr key={index} className="border border-black-b">
                                {!(item.order_status == 3) && (
                                    <td className="px-2 py-1 border border-black text-center">
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(item)}
                                            onChange={() => handleToggleSelect(index)}
                                        />
                                    </td>
                                )}
                                {(item.order_status == 3) && (
                                    <td className="px-2 py-1 border border-black text-center"></td>
                                )}
                                <td className="px-2 py-1 border border-black text-center">{item.ingredient}</td>
                                <td className="px-2 py-1 border border-black text-center">{getDateWithDay(item.date)}</td>
                                <td className="px-2 py-1 border border-black text-center">
                                    <a
                                        href="#"
                                        className="underline text-blue-500"
                                        onClick={(e) => displayIngredientDetail(e, item)}
                                    >
                                        {item.gross_required_qty}
                                    </a>
                                </td>
                                <td className="px-2 py-1 border border-black text-center">
                                    <input
                                        type="number"
                                        name="available_qty"
                                        disabled={item.order_status == 3}
                                        value={item?.available_qty ?? 0}
                                        onChange={handleInputChange(index)}
                                        className={`px-2 py-1 border rounded-md w-20 ${item.order_status == 3 ? "" : "bg-orange-50"}`}
                                    />
                                </td>
                                <td className="px-2 py-1 border border-black text-center">{item.required_qty}</td>
                                <td className="px-2 py-1 border border-black text-center">
                                    <input
                                        type="number"
                                        name="liters_kgs"
                                        disabled={item.order_status == 3}
                                        value={item?.order_qty ?? ''}
                                        onChange={handleInputChange(index)}
                                        className={`px-2 py-1 border rounded-md w-20 ${item.order_status == 3 ? "" : "bg-orange-50"}`}
                                    />
                                </td>
                                <td className="px-2 py-1 border border-black text-center">
                                    {item?.crates_qty ?? ''}
                                </td>
                                <td className="px-2 py-1 border border-black text-center">{item.std_qty_per_crate}</td>
                                <td className="px-2 py-1 border border-black text-center">
                                    {item.order_status == 1 ? "Pending Order" :
                                        (item.order_status == 2 ? "Pending Order" : "Order Placed")}
                                </td>
                                <td className="px-2 py-1 border border-black text-center text-blue-500 underline cursor-pointer">
                                    <a
                                        href="#"
                                        className="underline text-blue-500"
                                        onClick={(e) => displayOrderDetails(e, item)}
                                    >
                                        {item.order_id}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
            <IngredientBatchModel
                showModal={showModal}
                setShowModal={setShowModal}
                detailList={itemIngedientList}
                clearItem={clearItem}
                page = "dairy_list"
                type={1}
            />
            <OrderIngredientModel
                showOrderModal={showOrderModal}
                setShowOrderModal={setShowOrderModal}
                ingredientList={orderDetails}
                clearItem={clearItem}
                page="dairy_order"
            />
            {ingredientOrderList.length > 0 && (
                <div className='m-5'>
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                    >
                        Save
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 bg-rose-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
                    >
                        Place for Order
                    </button>
                </div>
            )}
        </div>
    );
}

export default DairyOrderList;

