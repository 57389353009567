import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { DairyRequirementFilter } from '../../components/Filter/DairyRequirementFilter.js';
import axios from 'axios';
import { baseUrl } from '../../comman/config';
import Select from 'react-select';
import Loader from "../../components/Loader.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";
import { useNavigate } from 'react-router-dom';


function DairyReceiptList() {
  const [ingredientReceiptList, setIngredientReceiptList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [receiptStatus, setReceiptStatus] = useState([]);
  const [ingredient, setIngredient] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [ingredientsListForStdQty, setIngredientsListForStdQty] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    getIngredientReceiptList();
  }, [fromDate, toDate, ingredient, receiptStatus])

  useEffect(() => {
    console.log(ingredientReceiptList)
    getIngredientList()
    getIngredientListForStdQty()
  }, [ingredientReceiptList])

  const handelFilterChange = async (from_date, to_date, order_status, order_id, ingredient, receipt_status) => {
    setFromDate(from_date);
    setToDate(to_date);
    setIngredient(ingredient || []);
    setReceiptStatus(receipt_status || []);
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const existingIngredientIds = ingredientReceiptList.map(item => item.ingredient_id);
    const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 2 && !existingIngredientIds.includes(item.id));
    setIngredientList(list);
  };

  const getIngredientListForStdQty = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 2);
    setIngredientsListForStdQty(list);
  };
  const getIngredientReceiptList = async () => {
    setLoading(true)
    setNoRecordsFound(false)

    // Prepare receipt status values
    let statusValues = [];

    // If receiptStatus contains the "All" option (value 0), don't include any status filter
    if (Array.isArray(receiptStatus) && receiptStatus.length > 0) {
      if (receiptStatus.some(item => item.value === 0)) {
        // Don't set any status values - this will show all statuses
        statusValues = null;
      } else {
        // Extract the values from the array of objects
        statusValues = receiptStatus.map(item => item.value);
      }
    } else if (receiptStatus?.value) {
      // Single select case
      statusValues = [receiptStatus.value];
    }


    const response = await axios.post(`${baseUrl}/dairy_receipt/list`,
      {
        from_date: fromDate,
        to_date: toDate,
        ingredient: ingredient?.value,
        receipt_status: statusValues

      });

    const updatedArray = response?.data?.data.map(item => ({
      ...item,         // Spread existing properties
      rec_crates_qty: item.rec_crates_qty ?? 0,
      received_qty: item.received_qty ?? "",
      crates_qty: item.crates_qty ?? 0,
      order_qty: item.order_qty ?? 0,
      diff_crates: item.rec_crates_qty - item.crates_qty ?? 0,
      diff_qty: item.received_qty - item.order_qty ?? 0,
      std_qty_per_crate: item.std_qty_per_crate ?? 0,
      add_received: false,
    }));
    setIngredientReceiptList(updatedArray);
    setNoRecordsFound(updatedArray.length === 0);
    setLoading(false)
  }

  const handleToggleSelect = (index) => {
    const updatedSelectedRows = selectedRows.includes(ingredientReceiptList[index])
      ? selectedRows.filter(item => item !== ingredientReceiptList[index]) // Deselect
      : [...selectedRows, ingredientReceiptList[index]]; // Select

    setSelectedRows(updatedSelectedRows);
  };

  const handleSwitchSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(ingredientReceiptList.filter((item) => item.receipt_status == 1)); // Select all rows
    }
    setSelectAll(!selectAll);
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;

    setIngredientReceiptList((prevFormData) => {
      if (name === 'rec_crates_qty') {
        const updatedFormData = [...prevFormData];
        const crates_qty = updatedFormData[index].crates_qty;
        const diff_crates = (value - crates_qty).toFixed(2);
        if (!updatedFormData[index].std_qty_per_crate) {
          const addStdQtyPrompt = window.confirm(
            // `Standard quantity per crate is missing for ${updatedFormData[index].ingredient}. Would you like to add it now?`
            `${updatedFormData[index].ingredient} માટે સ્ટાન્ડર્ડ માત્રા પ્રતિ ક્રેટ ખૂટી છે. તમે તેને હમણાં ઉમેરવા માંગો છો?`
          );

          if (addStdQtyPrompt) {
            // Extract ingredient_id from the object
            const ingredientId = updatedFormData[index]?.ingredient_id;

            if (ingredientId) {
              console.log("id:", ingredientId);
              editIngredient(ingredientId); // Pass ingredient_id correctly
            }
          }
          return prevFormData; // Prevent updating the state
        }

        const received_qty = value ? (value * updatedFormData[index].std_qty_per_crate) : 0;
        const diff_qty = (received_qty - updatedFormData[index].order_qty).toFixed(2);
        updatedFormData[index] = { ...updatedFormData[index], [name]: value, diff_crates: diff_crates, diff_qty: diff_qty, received_qty: received_qty };
        return updatedFormData;
      }
    });
  };

  const editIngredient = async (id) => {
    navigate(`/ingredients_receipt/edit/${btoa(id)}`, {
      state: ingredientsListForStdQty.filter(item => item.id == id)
    });
  };

  const handleSubmit = async () => {
    if (selectedRows.length == 0) {
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if (selectedRows.some(item => item.received_qty === "")) {
      alert("One or more items have an empty recieved qty value.");
      setSelectedRows([]);
      return;
    }
    if (selectedRows.some(item => item.add_received_qty === "")) {
      alert("One or more items have an empty additional recieved qty value.");
      setSelectedRows([]);
      return;
    }
    if (selectedRows.some(item => item.ingredient_id === null)) {
      alert("One or more items have an empty ingredient value.");
      setSelectedRows([])
      return
    }
    if (selectedRows.length > 0) {
      try {
        const response = await axios.post(`${baseUrl}/dairy_receipt/dairy_purchase`,
          {
            ingredientsList: selectedRows
          });
        const msg = response.data.msg;
        toast.success(msg);
        getIngredientReceiptList();
      } catch (error) {
        toast.error("Failed to add item. Please try again.");
      }
    }
    setSelectedRows([])
  };

  const handleAddNewIngredientRow = () => {
    setIngredientReceiptList([...ingredientReceiptList, {
      ingredient: '',
      ingredient_obj: {},
      ingredient_id: null,
      std_qty_per_crate: 0,
      rec_crates_qty: '',
      received_qty: '',
      order_qty: 0,
      crates_qty: 0,
      diff_crates: 0,
      diff_qty: 0,
      purchase_status: 1,
      receipt_status: 1,
      isNew: true
    }]);
  };

  const handleNewIngredientChange = (selectedOptions, index) => {
    console.log("selected option:", selectedOptions)
    const updatedList = [...ingredientReceiptList]
    updatedList[index].ingredient_obj = selectedOptions
    updatedList[index].ingredient = selectedOptions.ingredient
    updatedList[index].ingredient_id = selectedOptions.value
    updatedList[index].std_qty_per_crate = selectedOptions.std_qty_per_crate
    setIngredientReceiptList(updatedList)
  }

  return (
    <div className="p-4">
      {loading && <Loader />}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Receipt
        </h1>

        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DairyRequirementFilter handleFilterChange={handelFilterChange} page="receipt" />
      </div>
      {noRecordsFound && <NoRecordsFound />}
      {ingredientReceiptList.length > 0 && <div className="overflow-x-auto">
        <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-1 border border-black text-center " rowSpan="2"><input type="checkbox" className="newrow checkboxAll" onChange={handleSwitchSelectAll} checked={selectAll} /></th>
              <th scope="col" className="px-2 py-1 border border-black text-center " rowSpan="2">Ingredient</th>
              <th scope="col" className="px-2 py-1 border border-black text-center " rowSpan="2">Meal Date</th>
              <th scope="col" className="px-2 py-1 border border-black text-center" colSpan='2'>Received Qty</th>
              <th scope="col" className="px-2 py-1 border border-black text-center" colSpan='2'>Order qty</th>
              <th scope="col" className="px-2 py-1 border border-black text-center " colSpan='2'>Diff Qty</th>
              <th scope="col" className="px-2 py-1 border border-black text-center " rowSpan="2">Receipt Status</th>
              <th scope="col" className="px-2 py-1 border border-black text-center " rowSpan="2">Order No.</th>
            </tr>
            <tr>
              <th scope="col" className="px-2 py-1 border border-black text-center ">Crates</th>
              <th scope="col" className="px-2 py-1 border border-black text-center ">Liters/kgs.</th>
              <th scope="col" className="px-2 py-1 border border-black text-center ">Crates</th>
              <th scope="col" className="px-2 py-1 border border-black text-center ">Liters/kgs</th>
              <th scope="col" className="px-2 py-1 border border-black text-center ">Crates</th>
              <th scope="col" className="px-2 py-1 border border-black text-center ">Liters/kgs</th>
            </tr>
          </thead>
          <tbody>
            {ingredientReceiptList.map((item, index) => (
              <tr key={index} className="border border-black-b">
                <td className="px-2 py-1 border border-black text-center"><input type="checkbox" checked={selectedRows.includes(item)} onChange={() => handleToggleSelect(index)} /></td>
                <td className="px-2 py-1 border border-black text-center">
                  {item.isNew ? (
                    <Select
                      // isMulti
                      classNamePrefix="select"
                      className='basic-multi-select'
                      value={item.ingredient_obj}
                      name="ingredient"
                      onChange={(selectedOptions) => handleNewIngredientChange(selectedOptions, index)}
                      options={ingredientList.map((item) => ({
                        value: item.id,
                        label: `${item.ingredient} (${item.eng_name})`,
                        unit: item.unit?.id,
                        ingredient: `${item.ingredient}`,
                        eng_name: item.eng_name,
                        std_qty_per_crate: item.std_qty_per_crate,
                      }))}
                      placeholder="Ingredient List"
                      filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                      }}
                    />
                  ) : (item.ingredient)}
                </td>
                <td className="px-2 py-1 border border-black text-center">{item.date}</td>
                <td className="px-0 py-1 border border-black text-center">
                  <input type="number"
                    id="rec_crates_qty"
                    name="rec_crates_qty"
                    disabled={item.receipt_status == 3}
                    value={item?.rec_crates_qty ?? ''}
                    onWheel={(e) => e.target.blur()}
                    onChange={handleInputChange(index)}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault(); // Disable up and down arrow keys
                      }
                    }}
                    className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                  />
                </td>
                <td className="px-2 py-1 border border-black text-center">{item?.received_qty ?? ''}</td>

                <td className="px-2 py-1 border border-black text-center">{item.crates_qty}</td>
                <td className="px-2 py-1 border border-black text-center">{item.order_qty}</td>


                <td className={`px-2 py-1 border border-black text-center ${item.diff_crates < 0 ? 'bg-red-400 text-white' : ''}`}>{item.diff_crates}</td>
                <td className={`px-2 py-1 border border-black text-center ${item.diff_qty < 0 ? 'bg-red-400 text-white' : ''}`}>{item.diff_qty}</td>
                <td
                  className={`px-2 py-1 border border-black text-center 
    ${item.receipt_status === 1 ? "text-red-500" :
                      item.receipt_status === 2 ? "text-orange-500" :
                        "text-black"}`}
                >
                  {item.receipt_status === 1 ? "Pending Receipt" :
                    item.receipt_status === 2 ? "Partly Received" :
                      item.receipt_status === 3 ? "Received" :
                        item.receipt_status === 4 ? "Excess Received" :
                          "All"}
                </td>
                <td className="px-2 py-1 border border-black text-center">{item.order_id}</td>


              </tr>
            ))}
          </tbody>
        </table>
        <div className='m-5'>

          <button
            onClick={handleAddNewIngredientRow}
            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
          >
            Add Ingredient
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-rose-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
          >
            Received
          </button>
        </div>
      </div>}
    </div>)
}

export default DairyReceiptList;