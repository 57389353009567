
import React, { useState, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom';
import EditIngredientForm from '../../components/Form/Master/IngredientReceiptForm';


export default function EditIngredientReceipt() {

    const location = useLocation();
    const detail = location.state;

    return (
        <div className="p-4">
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Ingredients Std. Qty. Add</h1>
                <div>
                    <Link to="/dairy_receipt/list">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-2">
                <EditIngredientForm detail={detail} />
            </div>
        </div>
    )
}
