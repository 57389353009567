import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {baseUrl, addDays, orderStatusList, receiptStatusList, dairyReceiptStatusList } from "../../comman/config";

const DairyRequirementFilter = ({ handleFilterChange, page }) => {
    const today = new Date().toISOString().split('T')[0];
    const twoDaysLater = addDays(new Date(), 2).toISOString().split('T')[0];

    const [fromDate, setFromDate] = useState(page === "order" ? twoDaysLater : today);
    const [toDate, setToDate] = useState(page === "order" ? twoDaysLater : today);
    
    const [orderStatus, setOrderStatus] = useState(orderStatusList[1]);
    const [orderId, setOrderId] = useState(null);
    const [ingredient, setIngredient] = useState(null);
    const [ingredientList, setIngredientList] = useState([]);
    const [orderIdList, setOrderIdList] = useState([]);
    const [filter, setFilter] = useState(false);
    const [item, setItem] = useState([]);
    const [receiptStatus, setReceiptStatus] = useState( page === "receipt" 
        ? [dairyReceiptStatusList[1], dairyReceiptStatusList[2]] 
        : null);

    useEffect(() => {
        getIngredientList();
        getOrderIdList();
    }, []);

    const getIngredientList = async () => {
        const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
        const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 2);
        setIngredientList(list);
    };

    const getOrderIdList = async () => {
        const condition = `order_id IS NOT NULL GROUP BY order_id`;
        const response = await axios.get(`${baseUrl}/comman/list?table=item_requirement_summary&condition=${condition}`,
            { headers: { 'Cache-Control': 'no-cache' } }
        );
        setOrderIdList(response.data.data);
    };

    const handleDateChange = (e) => {
        const {name, value} = e.target;
        if(name === "from_date"){
            setFromDate(value);
        } else if(name === "to_date"){
            setToDate(value);
        }
    };

    const handleSubmit = () => {
        handleFilterChange(
            fromDate, 
            toDate, 
            orderStatus, 
            orderId, 
            ingredient,
            receiptStatus
        );
        setFilter(true);
    };

    const handleSelectChange = (data, option) => {
     
        if(option.name == 'order_status'){
            setOrderStatus(data);
        }else if(option.name == 'order_id'){
            setOrderId(data);
        }else if(option.name == 'receipt_status'){
            if(Array.isArray(data) && data.some(item => item.value === 0)) {
                // Only keep the "All" option
                const allOption = dairyReceiptStatusList.find(item => item.value === 0);
                setReceiptStatus([allOption]);
              } else {
                setReceiptStatus(data);
              }
            //setReceiptStatus(data);
        }
        
    };

    const handleChangeMultiSelect = (selected, name) => {
        if(name === "ingredient"){
            setIngredient(selected);
        }
    };

    const handleClear = () => {
        setFromDate(today);
        setToDate(today);
        setOrderStatus(orderStatusList[1]);
        setReceiptStatus([dairyReceiptStatusList[1],dairyReceiptStatusList[2]]);
        setOrderId(null);
        setIngredient(null);
        
        handleFilterChange(
            today, 
            today, 
            orderStatusList[1], 
            null, 
            null
        );
        setFilter(false);
    };

    return (
        <div className="pb-4 flex flex-column max-sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center">
            <div className='m-5 max-sm:m-1'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="from_date"
                    onChange={handleDateChange}
                    value={fromDate}
                />
            </div>
            <div className='m-5 max-sm:m-1'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="to_date"
                    onChange={handleDateChange}
                    value={toDate}
                />
            </div>
            {page == "order" && 
            <>
            <div className='m-5'>
                <Select
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={ingredient}
                    name="ingredient"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'ingredient')}
                    options={ingredientList.map((item) => ({
                        value: item.id,
                        label: `${item.ingredient} (${item.eng_name})`,
                        eng_name: item.eng_name,
                    }))}
                    placeholder="Ingredient List"
                    isClearable
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            <div className='m-5'>
                <Select
                    classNamePrefix="select"
                    value={orderId}
                    name="order_id"
                    className='w-32'
                    placeholder="Order Id"
                    onChange={(selectedOption, name) => {
                        handleSelectChange(selectedOption, name);
                    }}
                    options={orderIdList.map(item => ({
                        value: item.order_id,
                        label: item.order_id
                    }))}
                    isClearable
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            <div className='m-5'>
                <Select
                    classNamePrefix="select"
                    value={orderStatus}
                    name="order_status"
                    className='w-32'
                    onChange={(selectedOption, name) => {
                        handleSelectChange(selectedOption, name);
                    }}
                    options={orderStatusList.map(item => ({
                        value: item.value,
                        label: item.label
                    }))}
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            </>}
            {page == "receipt" && 
            <>
                <div className='m-5'>
                    <Select
                        // isMulti
                        classNamePrefix="select"
                        className='basic-multi-select'
                        value={ingredient}
                        name="ingredient"
                        onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'ingredient')}
                        options={ingredientList.map((item) => ({
                            value: item.id,
                            label: `${item.ingredient} (${item.eng_name})`,
                            eng_name: item.eng_name,
                          }))}
                        placeholder="Ingredient List"
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>
                
                <div className='m-5'>
                    <Select
                        classNamePrefix="select"
                        value={receiptStatus}
                        name="receipt_status"
                        isMulti={true} 
                        onChange={(selectedOption, name) => {
                            handleSelectChange(selectedOption, name);
                        }}
                        options={dairyReceiptStatusList.map(item => ({
                            value: item.value,
                            label: item.label
                        }))}
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>
            
            </>}

            <div className='m-5'>
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button>
                {filter && 
                 <button
                    onClick={handleClear}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Clear
                </button>}
            </div>
        </div>
    );
};

export {DairyRequirementFilter};