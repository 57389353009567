// IngredientBatchModel.js
import React from "react";
import { formatDate } from "../comman/config";

const IngredientBatchModel = ({ showModal, setShowModal, detailList, clearItem, type, page  }) => {

  const handleClose = () => {
    setShowModal(false);
    clearItem(); // Clear the selected item when closing the modal
  };
  if (!showModal) return null;

  // Extract Ingredient and Date from the first item (assuming it's the same for all rows)
  const ingredientName = detailList.length > 0 ? detailList[0].ingredient : "";
  const mealDate = detailList.length > 0 ? detailList[0].date : "";

  const totalGrossQty =
  type === 1 && page === "dairy_list"
    ? detailList.reduce((acc, item) => acc + parseFloat(item.gross_required_qty || 0), 0)
    .toFixed(2)
    : null;

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-0 border-b border-solid border-blueGray-200 rounded-t">
                  
                    
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* Ingredient & Date Display */}
                {type == 1 && (
                <div className="p-4">
                  <p className="text-lg">Ingredient : {ingredientName}</p>
                  <p className="text-lg pt-2">Date : {mealDate}</p>
                </div>
                )}
                
                <div className="relative p-2 flex-auto">
                  {type == 1 && <table className="border w-full">
                    <thead>
                      <tr>
                        {/* <th className="p-2">Ingredient</th>
                        <th className="p-2">Date</th> */}
                        <th className="p-2">Session</th>
                        <th className="p-2">Menu Item</th>
                        <th className="p-2">Gross Required Qty</th>
                        <th className="p-2">Kit No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailList.map((item, index) =>(
                        <tr key={index}>
                          {/* <td className="p-2 text-center">{item.ingredient}</td>
                          <td className="p-2 text-center">{item.date}</td> */}
                          <td className="p-2 text-center">{item.name}</td>
                          <td className="p-2 text-center">{item.item_name}</td>
                          <td className="p-2 text-center">{item.gross_required_qty}</td>
                          <td className="p-2 text-center">{item.kit_no}</td>
                        </tr>
                      ))}
                    </tbody>
                    {/* Show total row if page is dairy_list */}
                    {page === "dairy_list" && (
                        <tfoot>
                          <tr>
                            <td colSpan="2" className="p-2 text-right font-bold">Total :</td>
                            <td className="p-2 text-center font-bold">{totalGrossQty}</td>
                            <td></td>
                          </tr>
                        </tfoot>
                      )}
                  </table>}
                  {type == 2 && <table className="border w-full">
                    <thead>
                      <tr>
                        <th className="p-2">Meal Date</th>
                        <th className="p-2">Order ID</th>
                        <th className="p-2">Order Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                    {detailList.map((item, index) =>(
                      <tr key={index}>
                          <td className="p-2 text-center">{item.date}</td>
                          <td className="p-2 text-center">{item.order_id}</td>
                          <td className="p-2 text-center">{item.order_qty}</td>
                          </tr>
                    ))}
                    </tbody>
                  </table>}
                  {type == 3 && <table className="border w-full">
                    <thead>
                      <tr>
                        <th className="p-2">Order ID</th>
                        <th className="p-2">Purchase ID</th>
                        <th className="p-2">Purchase Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                    {detailList.map((item, index) =>(
                      <tr key={index}>
                          <td className="p-2 text-center">{item.order_id}</td>
                          <td className="p-2 text-center">{item.purchase_id}</td>
                          <td className="p-2 text-center">{item.purchase_qty}</td>
                          </tr>
                    ))}
                    </tbody>
                  </table>}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default IngredientBatchModel;
