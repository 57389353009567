import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, getDateWithDay } from "../../comman/config.js";
import { MainRequirementFilter } from "../../components/Filter/MainRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import Select from 'react-select';
import Loader from '../../components/Loader'

// import Pagination from "react-js-pagination";

function IssueList() {
  const today = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [session, setSession] = useState({});
  const [issueStatus, setIssueStatus] = useState({});
  const [item, setItem] = useState({});
  const [kit,setKit] = useState({})
  const [kitList,setKitList] = useState([])
  const [itemIngredientList, setItemIngredientList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [loading, setLoading] = useState(false)

  const [activeIndex, setActiveIndex] = useState(null);

  
  useEffect(()=>{
    getIngredientList()
  },[itemIngredientList])

  useEffect(() => {
   
    if(fromDate && toDate){
      getKitList();
    }
  }, [fromDate, toDate, session, item, issueStatus])

  useEffect(()=>{
    if(activeIndex !== null){
      getKitItemsList()
    }
  },[kit,item,activeIndex])

  const handelFilterChange = async (from_date, to_date, session, item, issueStatus) => {
        setActiveIndex(null)
        setFromDate(from_date);
        setToDate(to_date);
        setSession(session);
        setItem(item);
        setIssueStatus(issueStatus)
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const existingIngredientIds = itemIngredientList?.map(item => item.ingredient_id) || [];
    const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 1 && !existingIngredientIds.includes(item.id));
    // console.log(list)
    setIngredientList(list);
  };

  

  const getKitItemsList = async ()=>{
    setLoading(true);
    const kit = kitList.find((item, index) => index == activeIndex);
    // console.log(kit)
    const condition = `pr.menu_item_id='${kit.menu_item_id}' AND pr.date = '${kit.date}' AND pr.session='${kit.session}' AND pr.batch = '${kit.batch}' AND im.store = 1`;
    const RecipeDbData = await axios.get(`${baseUrl}/return_receipt/ingredient_list?condition=${condition}`);
    const ingredientsList = RecipeDbData.data.data;
   
    if(ingredientsList.length > 0){
      const updatedIngredientsList = [];
      for (const i of ingredientsList) {
      
        if (parseFloat(i.return_qty) > 0) {
          const condition = `date = '${kit.date}' AND session = '${kit.session}' AND menu_item_id = '${kit.menu_item_id}' AND batch = '${kit.batch}' AND ingredient_id = '${i.ingredient_id}'`;
          // console.log(condition);
          const response = await axios.get( `${baseUrl}/comman/list?table=item_requirement_detail&condition=${condition}`);
          
          let return_receipt_qty;
          let return_receipt_status;
          if(response.data.data.length > 0){
            return_receipt_qty = response.data.data[0].return_receipt_qty;
            return_receipt_status = response.data.data[0].return_receipt_status;
            // console.log(response.data.data);
          }

          console.log(return_receipt_status);
          updatedIngredientsList.push({
            ...i,
            return_qty : i.return_qty ?? 0,
            return_receipt_qty: return_receipt_qty ?? 0,
            return_receipt_status : return_receipt_status
          });
        }
        };
      // setSelectedItem(item);
      // console.log(updatedIngredientsList)
      setLoading(false);
      setItemIngredientList(updatedIngredientsList);
    }
  }

  const getKitList = async () => {
    setLoading(true);
    // let condition = `requirement_status = 2 AND ingredient_added = 2`;
    let condition = `1`;
    if(fromDate == toDate){
        condition += ` AND date = '${fromDate}' `;
    }else if(fromDate && toDate){
        
      condition += ` AND (date >= '${fromDate}' AND date  <= '${toDate}') `;
    }
    if(session?.value && session?.value != "undefined"){
      condition += ` AND session = '${session?.value}'`;
    }
    if(item?.value && item?.value != "undefined"){
      condition += ` AND menu_item_id = '${item?.value}'`;
    }
    // if(issueStatus?.value && issueStatus != 'undefined'){
    //   condition += ` AND return_receipt_status = '${issueStatus?.value}'`
    // }

    if(issueStatus.length > 0 && issueStatus != "undefined"){

      const hasAll = issueStatus.some((status) => status?.value == 0);

      if(!hasAll){
        condition += `AND (`;
  
        issueStatus.map((status, index) => {
          condition += ` return_receipt_status = '${status?.value}' `;
          if((index + 1)  != issueStatus.length){
            condition += `OR `;
          }
        })
  
        condition += ` )`
      }
    }
    
        const response = await axios.get(`${baseUrl}/comman/list?table=item_requirement_master&condition=${condition}`)
        const kitList = response.data.data;
        //setKitList(kitList)

    // Process each kit to check if it has ingredients with non-zero return quantities
    const filteredKitList = [];

    for (const kit of kitList) {
      const condition = `pr.menu_item_id='${kit.menu_item_id}' AND pr.date = '${kit.date}' AND pr.session='${kit.session}' AND pr.batch = '${kit.batch}' AND im.store = 1`;
      const recipeResponse = await axios.get(`${baseUrl}/return_receipt/ingredient_list?condition=${condition}`);

      // Only add kits that have data (the backend has already filtered out kits with all-zero return_qty)
      if (recipeResponse.data.data && recipeResponse.data.data.length > 0) {
        filteredKitList.push(kit);
      }
    }
    setKitList(filteredKitList);
    setLoading(false);
}
  

  const clearItem = () => {
    setActiveIndex(null)
    setItemIngredientList(null);
    getKitList()
  };

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setKit(kitList.find((item, i) => i == index))
  };
  

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setItemIngredientList((prevFormData) => {
      const updatedFormData = [...prevFormData];
      
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    
      return updatedFormData;
    });
  };

  const handleSave = async() => {
    if(itemIngredientList.some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      return
    }
    try{
        
        // console.log(itemIngredientList,kit)
      const response = await axios.post(`${baseUrl}/return_receipt/ingredient_update`,
        { ingredientsList : itemIngredientList,
          item: kit});
          toast.success("Item added successfully!");
          clearItem()
    }catch (error) {
      toast.error("Failed to add item. Please try again.");
    }
    
  }


  const handleAddNewIngredientRow = ()=> {
    setItemIngredientList([...itemIngredientList,{
      ingredient:'', 
      ingredient_obj:{},
      ingredient_id:null, 
      unit:null, 
      unitname:'', 
      wastage:0,
      gross_issued_qty:0, 
      // net_issued_qty:0, 
      gross_required_qty:0, 
      required_qty:0, 
      issue_status:1,
      isNew: true 
    }])
  }

  const handleNewIngredientChange = (selectedOptions,index) => {
    // setNewIngredient((prev)=>({...prev,ingredient:ingredient}))
    // console.log(selectedOptions)
    const updatedList =   [...itemIngredientList]
    updatedList[index].ingredient_obj = selectedOptions
    updatedList[index].ingredient = selectedOptions.ingredient
    updatedList[index].ingredient_id = selectedOptions.value
    updatedList[index].unit = selectedOptions.unit
    updatedList[index].unitname = selectedOptions.unitname
    // console.log(updatedList[index])
    setItemIngredientList(updatedList)
  }

  return (
    <div className="p-4">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
         Store Return Receipt
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <MainRequirementFilter handleFilterChange={handelFilterChange} page="receipt" />
      </div>

      <div>
        {kitList.map((item,index) => (
          <div key={index} className="border-b mt-4">
            <div
            className={`flex justify-between items-center p-4 cursor-pointer accordion-title border rounded"
            ${item.return_receipt_status == 1 ? 'bg-orange-400' : 
              (item.return_receipt_status == 2 ? 'bg-yellow-400' : 'bg-green-400')}`}
            onClick={() => handleClick(index)}>
            <span>{`${item.kit_no} (${item.return_receipt_status === 1 ? 'Pending Received' : (item.return_receipt_status == 2 ? "Partly Received" : "Received")  })`}</span>
            {index === activeIndex ? (
              <ChevronUpIcon className="w-6 h-6" />
            ) : (
              <ChevronDownIcon className="w-6 h-6" />
            )}
            </div>
            {index === activeIndex && (
                <div className="space-y-2 p-4 ">
                <div>
                  <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr> 
                        <th scope="col" className="px-2 py-1 border border-black text-center w-60 ">Ingredient</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Returned Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Received Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Receipt Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {itemIngredientList?.map((item, index) =>(
                                <tr key={index} className="border border-black-b">
                                  {item.isNew ? (<td className="px-2 py-1 border border-black text-center"><Select
                                      // isMulti
                                      classNamePrefix="select"
                                      className='basic-multi-select'
                                      value={item.ingredient_obj}
                                      name="ingredient"
                                      onChange={(selectedOptions) => handleNewIngredientChange(selectedOptions,index)}
                                      options={ingredientList.map((item) => ({
                                          value: item.id,
                                          label: `${item.ingredient} (${item.eng_name})`,
                                          unit:item.unit?.id,
                                          unitname:item.unit?.name,
                                          ingredient: `${item.ingredient}`,
                                          eng_name: item.eng_name,
                                        }))}
                                      placeholder="Ingredient List"
                                      filterOption={(option, inputValue) => {
                                          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                      }}
                                  /></td>) :  <td className="px-2 py-1 border border-black text-center">{item.ingredient}</td>}
                                  
                                  <td className="px-2 py-1 border border-black text-center">{ item?.return_qty ?? 0}</td>

                                  
                                  <td className="px-2 py-1 border border-black text-center"><input type="number" 
                                      id="return_receipt_qty" 
                                      name="return_receipt_qty" 
                                      value={item?.return_receipt_qty} 
                                      onWheel={(e) => e.target.blur()}
                                      onChange={handleInputChange(index)}
                                      onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                          e.preventDefault(); // Disable up and down arrow keys
                                        }
                                      }} 
                                      className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                                    /></td>
                                  <td className="px-2 py-1 border border-black text-center">{item.return_receipt_status == 3 ? "Received" : (item.return_receipt_status == 2 ? "Partly Received" : "Pending")}</td>
                                </tr>
                              ))}
                    </tbody>
                  </table>
                </div>
                
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                          {kit.issue_status !==3 &&(<>
                          {/* <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={item.issue_status == 3}
                              onClick={() => handleSumitForOrder()}
                            >
                              Submit for Issue
                            </button> */}
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={kit.issue_status == 3}
                              onClick={() => handleSave()}
                            >
                              Submit for Return
                            </button>
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={kit.issue_status === 3}
                              onClick={() =>{handleAddNewIngredientRow()}}
                            >
                              Add Ingredient
                            </button></>)}
                  </div>
                </div>)}
          </div>
        ))}
      </div>

      
    </div>
  );
}

export default IssueList;
