// DuplicateMenuCreation.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../../comman/config';
import Loader from '../../../components/Loader';
import MenuCreationForm from '../../../components/Form/Master/MenuCreationForm';
import { useParams } from 'react-router-dom';

const DuplicateMenuCreation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { mode } = useParams(); // 'edit' or 'duplicate'
    const { eventId, eventName, maharaj, bowlType, from_date, to_date, session_ids } = location.state || {};
    const [isEditMode] = useState(mode === 'edit');
    const [rows, setRows] = useState([
        { id: 1, track_for_report: false, menuitem_category: '', maharaj_id: '', menu_item_id: "", measurements: [], fg_consumption_per_person_kgs: "", fg_consumption_per_person_ltr: "", fg_consumption_per_person_nos: "", avg_density: "", avg_qty_in_1_bowl_ltr: '', avg_qty_in_1_bowl_kgs: '', avg_qty_in_1_bowl_nos: '' },
    ]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mealData, setMealData] = useState([]);
    const [formData, setFormData] = useState({
        event_name: '',
        start_date: '',
        end_date: ''
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [selectedMealType, setSelectedMealType] = useState(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [categories, setCategories] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [eventList, setEventList] = useState([]);
    const [bowlList, setBowlList] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [maharajList, setMaharajList] = useState([]);
    const [customSessions, setCustomSessions] = useState([]);
    const [showSessionModal, setShowSessionModal] = useState(false);
    const [customSessionOptions, setCustomSessionOptions] = useState([]);
    const [selectedCustomSessions, setSelectedCustomSessions] = useState([]);

    useEffect(() => {
        if (eventId) {
            fetchMealData(eventId);
            fetchCategories();
            getEventList();
            getBowlMasterList();
            getMethodList();
            getSessionList();
            getMaharajList();
        }
    }, [eventId]);

    // When in edit mode, we'll set the event_id in the form data
    useEffect(() => {
        if (isEditMode && eventId) {
            setFormData(prev => ({
                ...prev,
                event_id: eventId
            }));
        }
    }, [isEditMode, eventId]);

    const getEventList = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/comman/list?table=event_master&condition=status=1`);
            console.log("Response:", response);
            const data = response.data.data;
            setEventList(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching event list:', error);
            setError('Failed to load events');
            setLoading(false);
        }
    };

    const getBowlMasterList = async () => {
        const response = await axios.get(
            `${baseUrl}/comman/list?table=bowl_master&condition=status=1`
        );
        setBowlList(response.data.data);
    }

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${baseUrl}/item/category/list`);
            if (response.data.status) {
                setCategories(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const getMethodList = async () => {
        const response = await axios.get(
            `${baseUrl}/comman/list?table=method_master&condition=status=1`
        );
        setMethodList(response.data.data);
    };

    const getSessionList = async () => {
        try {
            const response = await axios.get(`${baseUrl}/comman/list?table=session_master`);
            const sessionData = response.data.data || [];
            setSessionList(sessionData);

            // Convert session_ids to a Set for faster lookup
            const sessionIdSet = new Set(session_ids?.map(id => id.toString()) || []);

            // Filter out default sessions and exclude those present in session_ids
            const custom = sessionData.filter(session =>
                !["Breakfast", "Lunch", "Dinner"].includes(session.name) &&
                !sessionIdSet.has(session.id.toString())
            );

            setCustomSessionOptions(custom);
            console.log("Custom sessions options:", custom);

            // Select sessions that exist in session_ids
            const selectedSessions = sessionData.filter(session =>
                sessionIdSet.has(session.id.toString())
            );

            setCustomSessions(selectedSessions);
            console.log("Selected sessions:", selectedSessions);
        } catch (error) {
            console.error("Error fetching sessions:", error);
        }
    };

    const fetchItemsByCategory = async (categoryId) => {
        try {
            const response = await axios.get(`${baseUrl}/item/list`);
            if (response.data.status) {
                const items = response.data.data.filter(item =>
                    item.category && item.category.id === parseInt(categoryId)
                );
                setAvailableItems(items);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const fetchMealData = async (id) => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/menu_creation/view?event_id=${id}`);
            if (response.data.status && response.data.data) {
                // Initialize the mealData structure with existing data
                const data = response.data.data;

                // For each day's meal data, ensure all session types have arrays
                const updatedData = data.map(day => {
                    // Ensure the standard meal types exist
                    if (!day.meals) {
                        day.meals = {
                            breakfast: [],
                            lunch: [],
                            dinner: []
                        };
                    } else {
                        // Ensure standard meal types are initialized as arrays
                        day.meals.breakfast = day.meals.breakfast || [];
                        day.meals.lunch = day.meals.lunch || [];
                        day.meals.dinner = day.meals.dinner || [];
                    }

                    // For any custom sessions in the data, ensure they are arrays
                    if (session_ids) {
                        sessionList.forEach(session => {
                            if (session_ids.includes(session.id.toString()) &&
                                !["Breakfast", "Lunch", "Dinner"].includes(session.name)) {
                                const sessionKey = session.name.toLowerCase().replace(/\s+/g, '_');
                                day.meals[sessionKey] = day.meals[sessionKey] || [];
                                console.log("Session key", sessionKey);
                            }
                        });
                    }


                    return day;
                });

                setMealData(updatedData);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching meal data:', error);
            setError('Failed to load meal data');
            setLoading(false);
        }
    };

    const handleAddSession = (selectedSessionIds) => {
        // Find the selected sessions from the options
        const sessionsToAdd = customSessionOptions.filter(session => selectedSessionIds.includes(session.id));

        // Add the new sessions to our customSessions array
        setCustomSessions(prev => {
            // Filter out any sessions already in the list
            const newSessions = sessionsToAdd.filter(session =>
                !prev.some(existingSession => existingSession.id === session.id)
            );
            return [...prev, ...newSessions];
        });

        // Update mealData to include new session types
        setMealData(prev => {
            return prev.map(day => {
                const updatedMeals = { ...day.meals };

                // Initialize new session arrays for each day
                sessionsToAdd.forEach(session => {
                    const sessionKey = session.name.toLowerCase().replace(/\s+/g, '_');
                    if (!updatedMeals[sessionKey]) {
                        updatedMeals[sessionKey] = [];
                    }
                });

                return {
                    ...day,
                    meals: updatedMeals
                };
            });
        });

        setShowSessionModal(false);
    };

    const handleRemoveSession = (sessionIdOrKey) => {
        // Find session info
        let sessionToRemove;
        let sessionKey;

        if (typeof sessionIdOrKey === 'number') {
            // It's a session ID
            sessionToRemove = customSessions.find(session => session.id === sessionIdOrKey);
            if (!sessionToRemove) return;
            sessionKey = sessionToRemove.name.toLowerCase().replace(/\s+/g, '_');
        } else {
            // It's a session key string
            sessionKey = sessionIdOrKey;
            sessionToRemove = customSessions.find(session => {
                const normalizedName = session.name.toLowerCase().replace(/\s+/g, '_');
                return normalizedName === sessionKey ||
                    session.name.toLowerCase() === sessionKey.replace(/_/g, ' ').toLowerCase();
            });
        }

        // Create the two key formats that are causing problems
        const keyWithUnderscore = sessionKey.replace(/\s+/g, '_');
        const keyWithSpace = sessionKey.replace(/_/g, ' ');

        console.log("Trying to remove with keys:", sessionKey, keyWithUnderscore, keyWithSpace);

        // Handle session list updates
        if (sessionToRemove) {
            // Remove from customSessions
            setCustomSessions(prev => prev.filter(session => session.id !== sessionToRemove.id));

            // Add to options
            setCustomSessionOptions(prev => {
                const alreadyExists = prev.some(option => option.id === sessionToRemove.id);
                if (!alreadyExists) return [...prev, sessionToRemove];
                return prev;
            });
        } else if (sessionKey) {
            // Handle default sessions
            const defaultSession = sessionList.find(session =>
                session.name.toLowerCase().replace(/\s+/g, '_') === sessionKey
            );

            if (defaultSession) {
                setCustomSessionOptions(prev => {
                    const alreadyExists = prev.some(option => option.id === defaultSession.id);
                    if (!alreadyExists) return [...prev, defaultSession];
                    return prev;
                });
            }
        }

        // Remove session from mealData - try BOTH key formats
        setMealData(prev => {
            return prev.map(day => {
                const updatedMeals = { ...day.meals };

                // Try both formats to ensure removal
                delete updatedMeals[sessionKey];
                delete updatedMeals[keyWithUnderscore];
                delete updatedMeals[keyWithSpace];

                return {
                    ...day,
                    meals: updatedMeals
                };
            });
        });
    };

    const handleInputChange = (e) => {

        // If in edit mode and trying to change event_id, prevent it
        if (isEditMode && e.target.name === 'event_id') {
            return;
        }

        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setValidationErrors(prev => ({
            ...prev,
            [name]: false
        }));

        // If event is selected, fetch its dates
        if (name === 'event_id' && value) {
            const selectedEvent = eventList.find(event => event.id === parseInt(value));
            if (selectedEvent) {
                setFormData(prev => ({
                    ...prev,
                    start_date: selectedEvent.start_date,
                    end_date: selectedEvent.end_date,
                    event_name: selectedEvent.event_name
                }));
                setMealData(prev => prev.map(day => ({
                    ...day,
                    date: ""
                })));
            }
            console.log("eventname: ", formData.start_date);
        }
    };

    const getMaharajList = async () => {
        const response = await axios.get(`${baseUrl}/maharaj/list`);
        const filteredList = response.data.data.filter((maharaj) => maharaj.status === 1)
        setMaharajList(filteredList);
    }

    const validateForm = () => {
        const errors = {};
        if (!formData.event_id) errors.event_id = true;

        let selectedEvent;
        if (isEditMode) {
            // In edit mode, we already have the event
            selectedEvent = eventList.find(event => event.id === parseInt(eventId));
            console.log("Edit mode event id:", selectedEvent);
        } else {
            // In duplicate mode, we need to check the selected event
            selectedEvent = eventList.find(event => event.id === parseInt(formData.event_id));
        }

        //const selectedEvent = eventList.find(event => event.id === parseInt(formData.event_id));
        if (!selectedEvent) errors.event_id = true;

        // Use the event's dates for validation
        const startDate = new Date(selectedEvent?.start_date);
        const endDate = new Date(selectedEvent?.end_date);

        const outOfRangeDates = mealData.filter(day => {
            if (!day.date) return true;
            const currentDate = new Date(day.date);
            return currentDate < startDate || currentDate > endDate;
        });

        if (outOfRangeDates.length > 0) {
            errors.dates_out_of_range = true;
        }

        const emptyDates = mealData.filter(day => {
            if (!day.meals) return true;
            return Object.values(day.meals).every(session => session.length === 0);
        });

        if (emptyDates.length > 0) {
            errors.empty_meal_dates = true;
        }

        const hasInvalidDates = mealData.some(day => !day.date);
        if (hasInvalidDates) {
            errors.meal_dates = true;
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            setError('Please fill all required fields correctly and ensure all dates are valid');
            return;
        }

        try {
            setLoading(true);

            let maharajData = [];
            let bowlTypeData = { value: '', label: '' };
            const newDates = mealData.map(day => day.date);

            if (maharaj) {
                if (typeof maharaj === 'string') {
                    maharajData = maharaj.split(',').map(id => ({
                        value: parseInt(id),
                        label: ''
                    }));
                } else if (Array.isArray(maharaj)) {
                    maharajData = maharaj.map(m => ({
                        value: m.id || m.value,
                        label: m.maharaj_name || m.label
                    }));
                }
            }

            if (isEditMode) {
                if (bowlType) {
                    bowlTypeData = {
                        value: typeof bowlType === 'object' ? bowlType.value : parseInt(bowlType),
                        label: typeof bowlType === 'object' ? bowlType.label : ''
                    };
                }
            } else {
                const selectedEvent = eventList.find(event => event.id === parseInt(formData.event_id));
                console.log("selected event:", selectedEvent);
                if (selectedEvent) {
                    bowlTypeData = {
                        value: parseInt(selectedEvent.bowl_type),
                        label: '' // Assuming the response has `bowl_type_name`
                    };
                }
            }

            const customSessionIds = customSessions.map(session => session.id);
            console.log("bowltypedata:", bowlTypeData);

            // Different endpoint based on mode
            const endpoint = isEditMode ?
                `${baseUrl}/menu_creation/edit` :
                `${baseUrl}/menu_creation/duplicate`;

            let payload;

            if (isEditMode) {
                // For edit mode
                payload = {
                    eventId: eventId,
                    fromDate: from_date,
                    toDate: to_date,
                    mealData: mealData,
                    newDates: newDates,
                    session_ids: [...(session_ids || []), ...customSessionIds]
                };
            } else {
                // For duplicate mode
                const selectedEventId = parseInt(formData.event_id);
                payload = {
                    new_eventId: selectedEventId,
                    fromDate: from_date,
                    toDate: to_date,
                    newDates: newDates,
                    eventId: eventId,
                    mealData: mealData,
                    bowlType: bowlTypeData,
                    session_ids: [...(session_ids || []), ...customSessionIds]
                };
            }
            console.log("Payload", payload);

            //const selectedEventId = parseInt(formData.event_id);

            console.log("meal data post api: ", mealData)
            // const duplicateResponse = await axios.post(`${baseUrl}/menu_creation/duplicate`, {
            //     new_eventId: selectedEventId,
            //     fromDate: from_date,
            //     toDate: to_date,
            //     newDates: newDates,
            //     eventId: eventId,
            //     mealData: mealData
            // });

            const duplicateResponse = await axios.post(endpoint, payload);

            if (duplicateResponse.data.status) {
                navigate('/menu_creation/view');
            } else {
                setError(duplicateResponse.data.message || 'Failed to duplicate meals');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.response?.data?.message || 'Failed to duplicate menu');
        } finally {
            setLoading(false);
        }
    };
    const handleCopyRow = (dayIndex) => {
        const rowToCopy = mealData[dayIndex];

        // Dynamically copy all available sessions
        const copiedMeals = Object.keys(rowToCopy.meals).reduce((acc, session) => {
            acc[session] = [...rowToCopy.meals[session]];
            return acc;
        }, {});

        const newRow = {
            ...rowToCopy,
            date: "",  // Reset date for new row
            meals: copiedMeals
        };

        const updatedMealData = [...mealData];
        updatedMealData.splice(dayIndex + 1, 0, newRow);
        setMealData(updatedMealData);
    };

    const handleRemoveRow = (dayIndex) => {
        setMealData(prev => prev.filter((_, index) => index !== dayIndex));
    };

    const handleDateChange = (index, newDate) => {
        const dateExists = mealData.some((data, i) => i !== index && data.date === newDate);
        if (dateExists) {
            setError('This date already exists in another row');
            return;
        }

        let selectedEvent;
        if (isEditMode) {
            selectedEvent = eventList.find(event => event.id === parseInt(eventId));
        } else if (formData.event_id) {
            selectedEvent = eventList.find(event => event.id === parseInt(formData.event_id));
        }

        // Validate against selected event's date range
        // if (formData.event_id) {
        //     const selectedEvent = eventList.find(event => event.id === parseInt(formData.event_id));
        if (selectedEvent) {
            const selectedDate = new Date(newDate);
            const eventStartDate = new Date(selectedEvent.start_date);
            const eventEndDate = new Date(selectedEvent.end_date);

            if (selectedDate < eventStartDate || selectedDate > eventEndDate) {
                setError('Selected date is outside of the event date range');
                return;
            }
            // }
        }

        setMealData(prev => {

            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                date: newDate
            };
            return updated;
        });
        setError(null);
    };

    const handleRemoveMenuItem = (dayIndex, mealType, itemIndex) => {
        setMealData(prev => {
            const updated = [...prev];
            const dayData = { ...updated[dayIndex] };
            const meals = { ...dayData.meals };
            meals[mealType] = meals[mealType].filter((_, index) => index !== itemIndex);
            dayData.meals = meals;
            updated[dayIndex] = dayData;
            return updated;
        });
    };

    const handleAddItem = (dayIndex, mealType) => {
        setSelectedDayIndex(dayIndex);
        setSelectedMealType(mealType);
        setShowAddItemModal(true);
    };

    const handleItemAdd = (item) => {
        setMealData(prev => {
            const updated = [...prev];
            //console.log("Meal Data: ", mealData);

            // Create a new item object using the data from the modal
            const newItem = {

                item_name: item.item_name,
                eng_name: item.eng_name || '',
                menu_item_id: item.menu_item_id.value,
                menuitem_category: item.menuitem_category,
                measurements: item.measurements,
                fg_consumption_per_person_kgs: item.fg_consumption_per_person_kgs,
                fg_consumption_per_person_ltr: item.fg_consumption_per_person_ltr,
                fg_consumption_per_person_nos: item.fg_consumption_per_person_nos,
                avg_density: item.avg_density,
                event_bowl_type: item.event_bowl_type,
                avg_qty_in_1_bowl_ltr: item.avg_qty_in_1_bowl_ltr,
                avg_qty_in_1_bowl_kgs: item.avg_qty_in_1_bowl_kgs,
                avg_qty_in_1_bowl_nos: item.avg_qty_in_1_bowl_nos,
                track_for_report: item.track_for_report || false,
                session_id: item.session || selectedCustomSessions,
                meal_type: item.meal_type,
                maharaj_id: item.maharaj_id
            };
            // FIRST: Ensure the selected meal type exists in all days (if using a custom session)
            // This makes sure the session structure exists across all days without adding the meal item
            if (selectedCustomSessions && !item.session) {
                // Add the session structure to all days without adding the meal item
                updated.forEach((day, dayIndex) => {
                    if (day && day.meals) {
                        // If the meal type doesn't exist in this day, initialize it as an empty array
                        if (!day.meals[selectedMealType]) {
                            day.meals[selectedMealType] = [];
                        }
                    }
                });
            }

            // SECOND: Add the meal item only to the selected day
            if (updated[selectedDayIndex] &&
                updated[selectedDayIndex].meals) {

                // Initialize the meal type array if it doesn't exist
                if (!updated[selectedDayIndex].meals[selectedMealType]) {
                    updated[selectedDayIndex].meals[selectedMealType] = [];
                }

                // Add the item only to the selected day
                updated[selectedDayIndex].meals[selectedMealType].push(newItem);
            } else {
                console.error("Invalid meal data structure:", updated, selectedDayIndex, selectedMealType);
            }

            return updated;
        });

        // Close the modal after adding the item
        setShowAddItemModal(false);
    };

    console.log("meal Data:", mealData);

    return (
        <>
            {loading && <Loader />}

            <MenuCreationForm
                details={{
                    eventName,
                    formData,
                    eventList,
                    bowlList,
                    baseUrl,
                    mealData,
                    validationErrors,
                    error,
                    loading,
                    categories,
                    availableItems,
                    selectedCategory,
                    setSelectedCategory,
                    showAddItemModal,
                    setShowAddItemModal,
                    handleInputChange,
                    handleDateChange,
                    handleCopyRow,
                    handleRemoveRow,
                    handleAddItem,
                    handleItemAdd,
                    handleRemoveMenuItem,
                    fetchItemsByCategory,
                    handleSubmit,
                    navigate,
                    rows,
                    setRows,
                    setMethodList,
                    methodList,
                    maharajList,
                    setMaharajList,
                    sessionList,
                    selectedMealType,
                    setAvailableItems,
                    isEditMode,
                    from_date,
                    to_date,
                    setMealData,
                    customSessions,
                    setCustomSessions,
                    handleAddSession,
                    handleRemoveSession,
                    showSessionModal,
                    setShowSessionModal,
                    customSessionOptions,
                    setCustomSessionOptions,
                    selectedCustomSessions,
                    setSelectedCustomSessions
                }}
            />
        </>
    );
};

export default DuplicateMenuCreation;