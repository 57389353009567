
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {baseUrl, limit} from "../../../comman/config";
import { PencilIcon, XCircleIcon } from '@heroicons/react/outline';
import { useNavigate, Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Select from 'react-select';
// import Pagination from "react-js-pagination";

function List() {

    const [ingredientList, setIngredientList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [category, setCategory] = useState(null);


    const navigate = useNavigate();

    useEffect(() => {
        getUsers();
        getIngredientCategories();
        
    }, []);

    useEffect(() => {
        let filterelist;
        if(searchQuery || category){
            filterelist = ingredientList.filter(item => {
                const matchesSearch = !searchQuery ||  item.eng_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.ingredient.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.category && item.category.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.category && item.category.eng_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.store?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.unit?.name.toLowerCase().includes(searchQuery.toLowerCase());

                const matchesCategory = !category ||
                    category.value === 'all' ||
                    (item.category && item.category.id === category.value);

                return matchesSearch && matchesCategory;

            });
        } else{
            filterelist = ingredientList;
        }  
        setFilteredList(filterelist);
    }, [searchQuery, category]);
    const getUsers = async () => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/ingredients/list?page=${page}&limit=${limit}`, {
           
        });
        setPopupOpen(false);
        setIngredientList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const getIngredientCategories = async () => {
        const response = await axios.get(`${baseUrl}/ingredients/category/list?condition=status=1`);
        const list = response.data.data;
        setCategoryList(list);
    };

    const handelFilterChange= (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };

    const handleSelectChange = (selectedOption) => {
        setCategory(selectedOption);
    };

    const deleteIngredient = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to disable this ingredient?");
        if (shouldDelete) {
            try {
                await axios.delete(`${baseUrl}/ingredients/delete/${id}`);
                window.location.reload();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const editIngredient = async (id) => {
        navigate(`/ingredients/edit/${btoa(id)}`, { state: ingredientList.filter(item => item.id == id) });
    }

    const handleExport = () => {
        const header = ["Ingredient name", "Category", "Store", "Unit"];
        const data =   filteredList.map((item) => {
            let resultArray = [];
            resultArray.push(`${item.ingredient} (${item.eng_name})`);
            resultArray.push(`${item?.category ? `${item.category.name} (${item.category.eng_name})` : "-"}`)
            resultArray.push(`${item.store?.name ?? "-"}`)
            resultArray.push(`${item.unit?.name ?? "-"}`)

            return resultArray;
        })
        
        
        const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ingredient List");

        XLSX.writeFile(workbook, `Ingredient_list.xlsx`);
    }


    return (
        <div className="p-4">
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Ingredients List</h1>
                <div>
                    <button onClick={handleExport} className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Export</button>
                    <Link to="/ingredients/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Ingredient</button>
                    </Link>
                </div>
            </div>

            {/* Search & Select Section */}
            <div className="pb-4 flex flex-wrap items-center space-x-4">
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <input type="text" value={searchQuery} onChange={handelFilterChange} id="table-search" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                </div>
                <Select
                    isMulti={false}
                    classNamePrefix="select"
                    className="w-48"
                    value={category}
                    name="category"
                    onChange={handleSelectChange}
                    options={[
                        { value: 'all', label: 'All Categories' },
                        ...categoryList.map(cat => ({
                            value: cat.id,
                            label: `${cat.name} ${cat.eng_name ? `(${cat.eng_name})` : ''}`
                        }))
                    ]}
                    placeholder="Select Category"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>

            {/* List Section */}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Ingredient name</th>
                            <th scope="col" className="px-6 py-3">Category</th>
                            <th scope="col" className="px-6 py-3">Store</th>
                            <th scope="col" className="px-6 py-3">Unit</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredList.map((item) => (
                            <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.ingredient} ({item.eng_name})</th>
                                <td className="px-6 py-4">{item?.category ? `${item.category.name} (${item.category.eng_name})` : "-"}</td>
                                <td className="px-6 py-4">{item.store?.name ?? "-"}</td>
                                <td className="px-6 py-4">{item.unit?.name ?? "-"}</td>
                                <td className="px-6 py-4 flex">
                                    {item.status == 1 && <a
                                        onClick={() => editIngredient(item.id)}
                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-5">
                                        <PencilIcon className="w-4 h-4 inline-block" />
                                    </a>}
                                    {item.status == 1 && <a href="#" onClick={() => deleteIngredient(item.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline">
                                        <XCircleIcon className="w-4 h-4 inline-block" />
                                    </a>}
                                </td>
                            </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default List;