import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { baseUrl } from '../../comman/config';

const MenuViewFilter = ({ handleFilterChange, handleExport, handleExportPdf, handleSubmitFilters }) => {
    const today = new Date().toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [session, setSession] = useState([]);
    const [item, setItem] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [filter, setFilter] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [event, setEvent] = useState({});

    useEffect(() => {
        getSessionList();
        getItemList();
        getEventList();
    }, []);

    const getSessionList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`);
        const sessionList = response.data.data;
        setSessionList(sessionList);
        setSession(sessionList.filter((item) => item.id == 1 || item.id == 2).map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        }));
    }

    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        const itemList = response.data.data;
        setItemList(itemList);
    }

    const getEventList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=event_master&condition=status=1`);
        const data = response.data.data;
        const newObject = { id: 'all', event_name: 'All' };
        data.unshift(newObject);
        setEventList(data);
        setEvent({ value: 'all', label: 'All' });
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === "from_date") {
            setFromDate(value);
            // Automatically apply filter when date changes
            handleFilterChange(value, toDate, session, item, event, false);
            setFilter(true);
        } else if (name === "to_date") {
            setToDate(value);
            // Automatically apply filter when date changes
            handleFilterChange(fromDate, value, session, item, event, false);
            setFilter(true);
        }
    };

    const handleSelectChange = (data, option) => {
        if (option.name === 'session') {
            // Handle "All" session logic
            if (data.some(d => d.value === 'all')) {
                setSession(sessionList.map(s => ({ value: s.id, label: s.name })));
            } else {
            setSession(data);
            }
            // Automatically apply filter when session changes
            handleFilterChange(fromDate, toDate, data, item, event);
        }
    };

    const defaultValues = {
        sessions: sessionList
            .filter((item) => item.id === 1 || item.id === 2)
            .map((item) => ({
                value: item.id,
                label: item.name,
            }))
    };

    const handleClear = () => {
        // handleFilterChange("", "");
        setSession(defaultValues.sessions);
        setItem([]);
        setFromDate(today);
        setToDate(today);
        setEvent({ value: 'all', label: 'All' });
        setFilter(false);

        handleFilterChange("", "", defaultValues.sessions, [], { value: 'all', label: 'All' }, true);
    }

    const handleChangeMultiSelect = (selected, name) => {
        if (name === 'item') {
            setItem(selected);
            // Automatically apply filter when item selection changes
            handleFilterChange(fromDate, toDate, session, selected, event, false);
            setFilter(true);
        }
    };

    // const handelEventChange = (selectedEvent) => {
    //     const data = eventList.filter(i => i.id == selectedEvent.value);
    //     setEvent({ value: data[0].id, label: data[0].event_name });

    //     // Only update dates if it's a real event (not "All")
    //     if (data[0].id !== 'all' && data[0].start_date && data[0].end_date) {
    //         setFromDate(data[0].start_date);
    //         setToDate(data[0].end_date);
    //         handleFilterChange(data[0].start_date, data[0].end_date, session, item, { value: data[0].id, label: data[0].event_name }, true);
    //     } else {
    //         handleFilterChange(fromDate, toDate, session, item, { value: data[0].id, label: data[0].event_name }, true);
    //     }
    //     setFilter(true);
    // }

    const handelEventChange = (selectedEvent) => {
        const data = eventList.filter(i => i.id == selectedEvent.value);
        setEvent({value: data[0].id, label: data[0].event_name});
        
        // If "All" is selected, don't set any date filters
        if (data[0].id === 'all') {
            // Pass empty strings for dates to show all data
            handleFilterChange("2024-01-01", today, session, item, {value: 'all', label: 'All'}, true);
        } else {
            // For specific events, use that event's dates
            setFromDate(data[0].start_date);
            setToDate(data[0].end_date);
            handleFilterChange(
                data[0].start_date, 
                data[0].end_date, 
                session, 
                item, 
                {value: data[0].id, label: data[0].event_name}, 
                true
            );
        }
        
        setFilter(true);
    };

    const clickSubmit = () => {
        // Call the submit function passed from parent
        handleSubmitFilters();
    }

    const clickExport = () => {
        handleExport();
    }
    const clickExportPDF = () => {
        handleExportPdf();
    }

    return (
        <div className="flex flex-wrap items-center pb-4 gap-4">
            <div className="m-2">
                <Select
                    classNamePrefix="select"
                    className='basic-single w-48'
                    value={event}
                    name="event_name"
                    onChange={(selectedOptions) => handelEventChange(selectedOptions)}
                    options={eventList.map(item => ({
                        value: item.id,
                        label: item.event_name
                    }))}
                    placeholder="Event List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    name="from_date"
                    onChange={handleDateChange}
                    value={fromDate}
                />
            </div>
            <div className="m-2">
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    name="to_date"
                    onChange={handleDateChange}
                    value={toDate}
                />
            </div>
            <div className="m-2">
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="w-48"
                    value={session.length === sessionList.length ? [{ value: 'all', label: 'All' }] : session}
                    name="session"
                    onChange={(selectedOption) => {
                        if (selectedOption.some(option => option.value === 'all')) {
                            setSession(sessionList.map(item => ({
                                value: item.id,
                                label: item.name
                            })));
                            handleFilterChange(fromDate, toDate, sessionList.map(item => ({
                                value: item.id,
                                label: item.name
                            })), item, event, false);
                        } else {
                            setSession(selectedOption);
                            handleFilterChange(fromDate, toDate, selectedOption, item, event, false);
                        }
                    }}
                    options={[
                        { value: 'all', label: 'All' },
                        ...sessionList.map(item => ({
                            value: item.id,
                            label: item.name
                        }))
                    ]}
                    placeholder="Select Sessions"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                <Select
                    isMulti
                    classNamePrefix="select"
                    className="basic-multi-select w-72"
                    value={item}
                    name="item"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'item')}
                    options={itemList.map(item => ({
                        value: item.id,
                        label: `${item.item_name} ${item.eng_name ? `(${item.eng_name})` : ''}`
                    }))}
                    placeholder="Item List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />
            </div>
            <div className="m-2">
                {filter &&(
                    <>
                
                    <button
                        onClick={handleClear}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                    >
                        Clear
                    </button>
                        <button
                            onClick={clickSubmit}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                        >
                            Submit
                        </button>
                    </>
                    
                )}
                <button
                    onClick={clickExport}
                    className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
                >
                    Export Excel
                </button>
                <button
                    onClick={clickExportPDF}
                    className="ml-2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
                >
                    Export PDF
                </button>
            </div>
        </div>
    );
};

export { MenuViewFilter };