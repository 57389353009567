import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { baseUrl, getDateWithDay } from '../../../comman/config';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";
import gujaratiFont from '../../../resources/NotoSansGujarati';

const MenuCreation = () => {
    const [loading, setLoading] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [mealData, setMealData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const defaultSessions = ["breakfast", "lunch", "dinner"];

    useEffect(() => {
        getEventList();
    }, []);

    const getEventList = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/comman/list?table=event_master&condition=status=1`);
            const data = response.data.data;
            setEventList(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching event list:', error);
            setError('Failed to load events');
            setLoading(false);
        }
    };

    const fetchMealData = async (eventId) => {
        try {
            setLoading(true);
            setError(null);
            const response = await axios.get(`${baseUrl}/menu_creation/view?event_id=${eventId}`);

            if (response.data.status && response.data.data) {
                // Data comes pre-organized from the backend now
                setMealData(response.data.data);
            } else {
                setMealData([]);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching meal data:', error);
            setError('Failed to load meal data');
            setLoading(false);
        }
    };

    const handleEventChange = (selectedOption) => {
        setSelectedEvent(selectedOption);
        if (selectedOption) {
            fetchMealData(selectedOption.value);
        } else {
            setMealData([]);
        }
    };
    console.log("mealdata: ", mealData)

    const handleDuplicate = () => {
        if (selectedEvent && mealData.length > 0) {
            const eventDetails = mealData[0]?.event_details || {};
            console.log("Session ids: ", eventDetails);
            navigate('/menu_creation/duplicate', {
                state: {
                    eventId: selectedEvent.value,
                    eventName: selectedEvent.label,
                    maharaj: eventDetails.maharaj,
                    bowlType: eventDetails.bowl_type,
                    from_date: eventDetails.start_date,
                    to_date: eventDetails.end_date,
                    session_ids: eventDetails.session_id
                }
            });
        }
    };

    const handleEdit = () => {
        if (selectedEvent && mealData.length > 0) {
            const eventDetails = mealData[0]?.event_details || {};

            console.log("Session ids: ", eventDetails);
            navigate('/menu_creation/edit', {
                state: {
                    eventId: selectedEvent.value,
                    eventName: selectedEvent.label,
                    maharaj: eventDetails.maharaj,
                    bowlType: eventDetails.bowl_type,
                    from_date: eventDetails.start_date,
                    to_date: eventDetails.end_date,
                    session_ids: eventDetails.session_id
                }
            });
        }

    };

    const handleExportExcel = () => {
        if (!mealData || mealData.length === 0) {
            console.log("No data to export");
            return;
        }
    
        const eventDetails = mealData[0]?.event_details || {};
        const eventName = selectedEvent?.label || "Event";
        const startDate = eventDetails.start_date ? new Date(eventDetails.start_date).toLocaleDateString("en-GB") : "";
        const endDate = eventDetails.end_date ? new Date(eventDetails.end_date).toLocaleDateString("en-GB") : "";
    
        const formattedTitle = `${eventName} General Kitchen Menu \nShibir Dates : ${startDate} - ${endDate}`;
    
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([]);
    
        // Add title at the top
        XLSX.utils.sheet_add_aoa(worksheet, [[formattedTitle]], { origin: "A1" });
    
        // Dynamically determine sessions
        const allSessions = mealData.length > 0 ? Object.keys(mealData[0].meals) : [];
    
        // Construct table headers dynamically
        const headers = ["Date", ...allSessions.map((session) => session.charAt(0).toUpperCase() + session.slice(1))];
    
        // Add table headers
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A3" });
    
        let rowIndex = 4; // Start data from row 4
    
        mealData.forEach((dayData) => {
            const dateObj = new Date(dayData.date);
            //const formattedDate = dateObj.toLocaleDateString("en-GB"); // DD-MM-YYYY format
            const formattedDate = `${dateObj.getDate()}-${dateObj.toLocaleString("en-GB", { month: "short" })}-${dateObj.getFullYear()}`;
            const dayName = dateObj.toLocaleDateString("en-GB", { weekday: "short" }); // Short weekday name (Mon, Tue, etc.)
    
            const fullDate = `${formattedDate}, ${dayName}`; // Example: 18-02-2009, Tue
    
            // Function to format meal items
            const formatMealItems = (items) => {
                if (!items || items.length === 0) return "";
                return items.map((item) => item.item_name).join(", ");
            };
    
            // Generate row dynamically based on session data
            const rowData = [fullDate, ...allSessions.map((session) => formatMealItems(dayData.meals[session]))];
    
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` });
            rowIndex++;
        });
    
        // Set column widths dynamically
        worksheet["!cols"] = [{ wch: 20 }, ...allSessions.map(() => ({ wch: 40 }))];
    
        // Merge title cells across all columns
        worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: headers.length - 1 } }];
    
        // Set row height for header and data rows
        if (!worksheet["!rows"]) worksheet["!rows"] = [];
        worksheet["!rows"][0] = { hpt: 30 }; // Title
        worksheet["!rows"][2] = { hpt: 20 }; // Header
    
        // Set taller row height for data rows to accommodate wrapped text
        for (let r = 3; r < rowIndex; r++) {
            worksheet["!rows"][r] = { hpt: 60 }; // Taller rows for data with wrapped text
        }
    
        // Download the file
        XLSX.utils.book_append_sheet(workbook, worksheet, "Menu Data");
        XLSX.writeFile(workbook, `${eventName}_MenuData.xlsx`);
    };

    const handleExportPdf = () => {
        if (!mealData || mealData.length === 0) {
            console.log("No data to export");
            return;
        }
    
        const eventDetails = mealData[0]?.event_details || {};
        const eventName = selectedEvent?.label || "Event";
        const startDate = eventDetails.start_date ? new Date(eventDetails.start_date).toLocaleDateString("en-GB") : "";
        const endDate = eventDetails.end_date ? new Date(eventDetails.end_date).toLocaleDateString("en-GB") : "";
    
        // Formatting event details for title
        const eventTitle = `${eventName} General Kitchen Menu`;
        const eventSubTitle = `Shibir Dates : ${startDate} - ${endDate}`;
    
        const doc = new jsPDF();
        doc.addFont(gujaratiFont, "Gujarati", "normal");
        doc.setFont("Gujarati");
    
        // Add centered title with event details
        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.text(eventTitle, pageWidth / 2, 15, { align: "center" });
        doc.text(eventSubTitle, pageWidth / 2, 25, { align: "center" });
    
        // Dynamically extract all session names
        const allSessions = mealData.length > 0 ? Object.keys(mealData[0].meals) : [];
    
        // Construct table headers dynamically
        const tableColumn = ["Date", ...allSessions.map(session => session.charAt(0).toUpperCase() + session.slice(1))];
    
        // Prepare table data dynamically
        const tableRows = mealData.map(dayData => {
            const dateObj = new Date(dayData.date);
            //const formattedDate = dateObj.toLocaleDateString("en-GB"); // DD-MM-YYYY format
            const formattedDate = `${dateObj.getDate()}-${dateObj.toLocaleString("en-GB", { month: "short" })}-${dateObj.getFullYear()}`;
            const dayName = dateObj.toLocaleDateString("en-GB", { weekday: "short" }); // Short weekday name (Mon, Tue, etc.)
    
            const fullDate = `${formattedDate}, ${dayName}`; // Example: 18-02-2009, Tue
    
            // Function to format meal items
            const formatMealItems = (items) => {
                if (!items || items.length === 0) return "";
                return items.map(item => item.item_name).join(", ");
            };
    
            // Generate row data dynamically based on available sessions
            return [fullDate, ...allSessions.map(session => formatMealItems(dayData.meals[session]))];
        });
    
        // Add table to PDF with font configuration
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            styles: {
                fontSize: 10,
                cellPadding: 4,
                font: "Gujarati",
                overflow: "linebreak",
                cellWidth: "wrap",
            },
            columnStyles: {
                0: { cellWidth: 50 }, // Date column
                ...Object.fromEntries(allSessions.map((_, index) => [index + 1, { cellWidth: "auto" }])), // Dynamic session columns
            },
        });
    
        // Save PDF
        doc.save(`${eventName}_MenuData.pdf`);
    };
    
    const allSessions = mealData.length > 0 
        ? Object.keys(mealData[0].meals) 
        : [];

    const renderMealItems = (items) => {
        if (!items || items.length === 0) return "-";

        return (
            <div className="flex flex-wrap gap-2">
                {items.map((item, index) => (
                    <span
                        key={`${item.id}-${index}`}
                        className="bg-gray-200 px-2 py-1 rounded-full text-sm"
                        title={item.eng_name} // Show English name on hover
                    >
                        {item.item_name}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className="p-4">
            {loading && <Loader />}

            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Menu Creation</h1>
                <div className="flex gap-4">
                    {selectedEvent && mealData.length > 0 && (
                        <>
                            <button
                                onClick={handleEdit}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                            >
                                Edit
                            </button>

                            <button
                                onClick={handleDuplicate}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                            >
                                Duplicate
                            </button>

                            <button
                                onClick={handleExportExcel}
                                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
                            >
                                Export Excel
                            </button>
                            <button
                                onClick={handleExportPdf}
                                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
                            >
                                Export PDF
                            </button>
                        </>
                    )}

                </div>
            </div>

            <div className="mb-6">
                <Select
                    className="w-64"
                    value={selectedEvent}
                    onChange={handleEventChange}
                    options={eventList.map(event => ({
                        value: event.id,
                        label: event.event_name
                    }))}
                    placeholder="Select Event"
                    isClearable
                />
            </div>

            {error && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
                    {error}
                </div>
            )}
            {/* Meal Data Table */}
            {selectedEvent && mealData.length > 0 && (
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 border">Date</th>
                                {allSessions.map((session) => (
                                    <th key={session} className="px-6 py-3 border capitalize">
                                        {session}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {mealData.map((dayData) => (
                                <tr key={dayData.date} className="border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 border font-medium">
                                        {getDateWithDay(dayData.date)}
                                    </td>
                                    {allSessions.map((session) => (
                                        <td key={session} className="px-6 py-4 border">
                                            {renderMealItems(dayData.meals[session] || [])}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {selectedEvent && mealData.length === 0 && (
                <div className="text-center p-8 bg-gray-50 rounded-md">
                    No meal data found for this event.
                </div>
            )}
        </div>
    );
};

export default MenuCreation;