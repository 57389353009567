import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';
import { baseUrl, getDateWithDay, requirementStatusList } from "../../comman/config";
import { VegRequirementFilter } from "../../components/Filter/VegRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import IngredientBatchModel from "../../components/IngredientBatchModel.js";
import OrderIngredientModel from "../../components/OrderIngredientModel.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";
import Loader from "../../components/Loader.js";
import * as XLSX from "xlsx";
// import Pagination from "react-js-pagination";

function OrderList() {
  const today = new Date().toISOString().split("T")[0];
  const [ingredientOrderList, setIngredientOrderList] = useState([]);
  const [orderStatus, setOrderStatus] = useState({});
  const [orderId, setOrderId] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ingredient, setIngredient] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [orderIngedientList, setOrderIngredientList] = useState(null);
  const [itemIngedientList, setItemIngredientList] = useState(null);
  const [methodList, setMethodList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  useEffect(() => {
    getMethodList()
  }, []);
useEffect(() => {
  getIngredientOrderList();
}, [fromDate, toDate, ingredient, orderStatus, orderId])

  const handelFilterChange = async (from_date, to_date, session, item, requirement_status, order_status, order_id, purchase_status, receipt_status,issueStatus,ingredient) => {
        setFromDate(from_date);
        setToDate(to_date);
        setIngredient(ingredient);
        setOrderStatus(order_status);
        setOrderId(order_id); 
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };
  const getIngredientOrderList = async() => {
    setLoading(true)
    setNoRecordsFound(false)
    const response = await axios.post(`${baseUrl}/order/list`,
      { from_date : fromDate,
        to_date :toDate,
        ingredient : ingredient?.value,
        order_status: orderStatus?.value,
        order_id : orderId?.value
       
    });
    const condition = (item) => `ird.date = '${item.date}' AND ird.ingredient_id = '${item.ingredient_id}' AND irm.requirement_status = 2 AND irm.ingredient_added = 2 AND ing.store = 3`;
    const fetchUpdatedArray = async (responseData) => {
      const updatedArray = [];
    
      for (const item of responseData.data.data) {
        // Construct the condition based on the current item
        const conditionString = condition(item);
    
        // Make the API call with the condition
        const RecipeDbData = await axios.get(`${baseUrl}/order/ingredient_list?condition=${conditionString}`);
    
        // Get the ingredients list from the API response
        const ingredientsList = RecipeDbData.data.data;
        const popupQty = ingredientsList.reduce((sum, ingredient) => sum + parseFloat((ingredient.gross_required_qty || 0)), 0).toFixed(2);
        // Update the item with the new data
        const updatedItem = {
          ...item,
          order_qty: item.order_qty ?? "", // Add the order_qty key-value pair
          popupQty, // Add the ingredientsList from the API response
        };
    
        // Add the updated item to the array
        updatedArray.push(updatedItem);
      }
    
      return updatedArray;
    };
    const updatedArray = await fetchUpdatedArray(response);
    setIngredientOrderList(updatedArray);
    setNoRecordsFound(updatedArray.length === 0);
    setLoading(false)
  }

  
  const handleSubmit = async() => {
    if(selectedRows.length == 0){
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if(selectedRows.some(item => item.order_qty === "")){
      alert("One or more items have an empty order qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.length > 0){
      setOrderIngredientList(selectedRows);
      try{
        if(window.confirm(`આ શાકભાજી "ઓર્ડર" થવા જઈ રહી છે. હવે પછી "ઓર્ડર" માં કોઈ સુધારો થઇ શકશે નહી .
  કોઈ કારણસર કોઈ સુધારો કરવાનો થાય તો "શાકભાજી પરચેઝ (ખરીદ) ટીમ" ને જાણ કરવી.
  શું તમે "ઓર્ડર" કરવા માંગો છો ?`)){
          const response = await axios.post(`${baseUrl}/order/ingredient_place_order`,
            { ingredientsList : selectedRows});
          const msg = response.data.msg;
          toast.success(msg);
          
          getIngredientOrderList();
        }
      }catch (error) {
        toast.error("Failed to add item. Please try again.");
      }
      // setShowOrderModal(true);
    }
    setSelectedRows([]);
  };

  const handleSave = async() => {
    if(selectedRows.length == 0){
      alert("Please select at least one row");
      setSelectedRows([]);
      return;
    }
    console.log(selectedRows);
    if(selectedRows.some(item => item.order_qty === "")){
      alert("One or more items have an empty order qty value.");
      setSelectedRows([]);
      return;
    }
    if(selectedRows.length > 0){
      try{
        const response = await axios.post(`${baseUrl}/order/ingredient_save`,
          { 
            ingredientsList : selectedRows
          });
          toast.success("Data save sucessfully.");
          getIngredientOrderList();
      }catch (error) {
        toast.error("Failed to add item. Please try again.");
      }
    }
  }

  const handleExport = async (e) => {
    e.preventDefault();
    try {
      const headerRow = [
        "Meal Date",
        "Ingredient",
        "Gross Req.Qty",
        "Available Stock",
        "Order Qty",
        "Order Status",
        "Order Id",
      ];
  
      const data = ingredientOrderList.map((item) => {
        // Replace status values with "Pending Order" or "Order Place"
        const status =
          item.order_status == 1 || item.order_status == 2
            ? "Pending Order"
            : "Order Place";
  
        return [
          item.date,
          item.ingredient,
          item.gross_required_qty,
          item.available_qty,
          item?.order_qty ?? "",
          status,
          item.order_id,
        ];
      });
  
      // Combine header row and data
      const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...data]);
  
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
  
      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
      // Create a Blob and trigger download
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "order.xlsx");
    } catch (error) {
      toast.error("Failed to export item. Please try again.");
    }
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setIngredientOrderList((prevFormData) => {
      const updatedFormData = [...prevFormData];
     
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });
  };

  const handleToggleSelect = (index) => {
    const updatedSelectedRows = selectedRows.includes(ingredientOrderList[index])
        ? selectedRows.filter(item => item !== ingredientOrderList[index]) // Deselect
        : [...selectedRows, ingredientOrderList[index]]; // Select

   setSelectedRows(updatedSelectedRows);
    

};
const handleSwitchSelectAll = () => {
  if (selectAll) {
      setSelectedRows([]);
  } else {
      setSelectedRows(ingredientOrderList.filter((item) => item.order_status == 1)); // Select all rows
  }
  setSelectAll(!selectAll);
};

  const displayIngredientDetail = async(e, item) => {
    e.preventDefault();
    setLoading(true)
    const condition = `ird.date = '${item.date}' AND ird.ingredient_id = '${item.ingredient_id}' AND irm.requirement_status = 2 AND irm.ingredient_added = 2`;
    const RecipeDbData = await axios.get(`${baseUrl}/order/ingredient_list?condition=${condition}`);
    const ingredientsList = RecipeDbData.data.data;
    
    setItemIngredientList(ingredientsList);
    setLoading(false)
    setShowModal(true);
  }

  const clearItem = () => {
    setSelectedRows([]);
    setItemIngredientList(null);
    setOrderIngredientList(null);
    getIngredientOrderList();
  };

  return (
    <div className="p-4">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
         Order
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <VegRequirementFilter handleFilterChange={handelFilterChange} page="order" />
        
       
       
      </div>
      {noRecordsFound && <NoRecordsFound/>}
      {ingredientOrderList.length > 0 && <div className="overflow-x-auto">
          <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 py-1 border border-black text-center "><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Meal Date</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Ingredient</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Gross Req. Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Available Stock</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Order Qty</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Order Status</th>
                <th scope="col" className="px-2 py-1 border border-black text-center ">Order Id</th>
              </tr>
            </thead>
            <tbody>
              {ingredientOrderList.map((item, index) => (
                <tr key={index} className="border border-black-b">
                  {!(item.order_status == 3 && item.purchase_status == 3) && <td className="px-2 py-1 border border-black text-center"><input type="checkbox" checked={selectedRows.includes(item)} onChange={() => handleToggleSelect(index)} /></td>}
                  {(item.order_status == 3 && item.purchase_status == 3) && <td className="px-2 py-1 border border-black text-center"></td>}
                  <td className="px-2 py-1 border border-black text-center">{getDateWithDay(item.date)}</td>
                  <td className="px-2 py-1 border border-black text-center">{item.ingredient}</td>
                  <td className={`px-2 py-1 border border-black text-center ${item.popupQty != item.gross_required_qty ? "bg-green-200" : ""}`}><a href="#" className="underline  text-blue-500" onClick={(e) => displayIngredientDetail(e, item)}>{item.gross_required_qty}</a></td>
                  <td className="px-2 py-1 border border-black text-center">{item.available_qty}</td>
                  <td className="px-2 py-1 border border-black text-center"><input type="number" 
                              id="order_qty" 
                              name="order_qty" 
                              disabled={item.order_status == 3 && item.purchase_status == 3}
                              value={item?.order_qty ?? ''} 
                              onChange={handleInputChange(index)}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                  e.preventDefault(); // Disable up and down arrow keys
                                }
                              }} 
                              className={`px-2 py-1 border rounded-md w-20 ${item.order_status == 3 && item.purchase_status == 3 ? "" : "bg-orange-50"}`}
                            /></td>
                  <td className="px-2 py-1 border border-black text-center">
                   {item.order_status == 1 ? "Pending Order" : (item.order_status == 2 ? "Pending Order" : "Order Place")}
                  </td>
                  <td className="px-2 py-1 border border-black text-center">
                   {item.order_id}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>}
        <IngredientBatchModel showModal={showModal} setShowModal={setShowModal} detailList={itemIngedientList} clearItem={clearItem} type={1} />
        <OrderIngredientModel showOrderModal={showOrderModal} setShowOrderModal={setShowOrderModal} ingredientList={orderIngedientList} clearItem={clearItem} />
        {ingredientOrderList.length > 0 && <div className='m-5'>
        <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Save
        </button>
        <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-rose-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
        >
          Place for Order
        </button>
        <button
            onClick={handleExport}
            className="px-4 py-2 bg-green-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
        >
          Export
        </button>
       
                </div>}
    </div>
  );
}

export default OrderList;
