import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../comman/config';
import { useNavigate } from 'react-router-dom';

export default function EditIngredientForm({ detail }) {
    const [formData, setFormData] = useState({
        ingredient_eng: '',
        ingredient_guj: '',
        ingredient_category: '',
        ingredient_unit: '',
        store: '',
        wastage: 0,
        std_qty_per_crate: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (detail) {
            setFormData({
                ingredient_eng: detail[0].eng_name || '',
                ingredient_guj: detail[0].ingredient || '',
                ingredient_category: detail[0]?.category?.id || '',
                ingredient_unit: detail[0]?.unit?.id || '',
                store: detail[0]?.store?.id || '',
                wastage: detail[0]?.wastage || 0,
                std_qty_per_crate: detail[0].std_qty_per_crate || ''
            });
        }
    }, [detail]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // console.log("formdata:", formData)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!detail || !detail[0].id) {
                console.error('Missing ingredient details for update.');
                return;
            }
            await axios.post(`${baseUrl}/ingredients/edit/${detail[0].id}`, {
                formData
            });
            navigate('/dairy_receipt/list');
        } catch (error) {
            console.error('Error updating ingredient:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-900">Ingredient Name (Eng)</label>
                <input
                    type="text"
                    name="ingredient_eng"
                    value={formData.ingredient_eng}
                    readOnly
                    className="block w-full border-gray-300 rounded-md shadow-sm text-gray-900 py-1.5 px-1.5"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">Ingredient Name (Guj)</label>
                <input
                    type="text"
                    name="ingredient_guj"
                    value={formData.ingredient_guj}
                    readOnly
                    className="block w-full border-gray-300 rounded-md shadow-sm text-gray-900 py-1.5 px-1.5"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">Std. Qty. per Crate</label>
                <input
                    type="number"
                    name="std_qty_per_crate"
                    value={formData.std_qty_per_crate}
                    onChange={handleInputChange}
                    className="block w-full border-gray-300 rounded-md shadow-sm text-gray-900 py-1.5 px-1.5"
                />
            </div>
            <div className="mt-4 flex justify-end">
                <button
                    type="submit"
                    className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600"
                >
                    Save
                </button>
            </div>
        </form>
    );
}