// MenuCreationForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";
import gujaratiFont from '../../../resources/NotoSansGujarati';
import { useLocation } from 'react-router-dom';

const EventSelectionForm = ({ formData, validationErrors, handleInputChange, eventList, isEditMode }) => {
  const location = useLocation()
  const { from_date, to_date } = location.state || {};
  // Get selected event details
  const selectedEvent = eventList.find(event => event.id === formData.event_id) || {};


  return (
    <div className="mb-6 bg-white p-4 rounded shadow">
      {!isEditMode && <h2 className="text-xl font-semibold mb-4">Select Target Event</h2>}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Select Event */}
        <div className="max-w-sm">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {isEditMode ? "Event" : "Select Event*"}
          </label>
          {isEditMode ? (
            <p className="p-2 border rounded bg-gray-100">{selectedEvent.event_name || "N/A"}</p>
          ) : (
            <select
              name="event_id"
              value={formData.event_id}
              onChange={handleInputChange}
              className={`w-full border rounded p-2 ${validationErrors.event_id ? 'border-red-500' : 'border-gray-300'}`}
              disabled={isEditMode} // Disable in edit mode
            >
              <option value="">Select an event</option>
              {eventList.map(event => (
                <option key={event.id} value={event.id}>
                  {event.event_name}
                </option>
              ))}
            </select>
          )}
          {!isEditMode && validationErrors.event_id &&
            <p className="text-red-500 text-sm mt-1">Please select an event</p>
          }
        </div>

        {/* Start Date (Non-editable) */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Date
          </label>
          <input
            type="date"
            value={isEditMode ? from_date : formData.start_date || ""}
            readOnly
            className="w-full border rounded p-2 bg-gray-100"
          />
        </div>

        {/* End Date (Non-editable) */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Date
          </label>
          <input
            type="date"
            value={isEditMode ? to_date : formData.end_date || ""}
            readOnly
            className="w-full border rounded p-2 bg-gray-100 "
          />
        </div>
      </div>

      {/* Validation Errors */}
      {validationErrors.dates_out_of_range &&
        <p className="text-red-500 text-sm mt-2">Some dates in the table are outside the selected event date range</p>
      }
      {validationErrors.meal_dates &&
        <p className="text-red-500 text-sm mt-2">All dates in the table must be specified</p>
      }
      {!formData.event_id && !isEditMode &&
        <p className="text-yellow-500 text-sm mt-2">Please select an event before editing table dates</p>
      }
      {validationErrors.empty_meal_dates &&
        <p className="text-red-500 text-sm mt-2">Some dates have no items. Please add items or remove these dates.</p>
      }
    </div>
  );
};

const MealTable = ({
  mealData,
  formData,
  eventList,
  handleDateChange,
  handleAddItem,
  handleRemoveMenuItem,
  handleCopyRow,
  handleRemoveRow,
  isEditMode,
  customSessions,
  handleRemoveSession
}) => {
  const location = useLocation();
  const { from_date, to_date } = location.state || {};
  const [showConfirm, setShowConfirm] = useState(false);
  const [dayIndexToRemove, setDayIndexToRemove] = useState(null);
  const [sessionToRemove, setSessionToRemove] = useState(null);
  const [showSessionConfirm, setShowSessionConfirm] = useState(false);

  // Get date constraints from selected event
  const selectedEvent = formData.event_id ?
    eventList.find(event => event.id === parseInt(formData.event_id)) : null;
  const minDate = selectedEvent?.start_date;
  const maxDate = selectedEvent?.end_date;

  const renderMealItems = (items, dayIndex, mealType) => {
    return (
      <div className="flex flex-wrap gap-2">
        {items.map((item, index) => (
          <span
            key={`${item.id}-${index}`}
            className="bg-gray-200 px-2 py-1 rounded-full text-sm flex items-center group"
            title={item.eng_name}
          >
            {item.item_name}
            <button
              onClick={() => handleRemoveMenuItem(dayIndex, mealType, index)}
              className="ml-1 text-gray-500 hover:text-red-600 group-hover:opacity-100 transition-opacity"
              title="Remove item"
            >
              <div>✖</div>
            </button>
          </span>
        ))}
      </div>
    );
  };

  const confirmRemove = (dayIndex) => {
    setDayIndexToRemove(dayIndex);
    setShowConfirm(true);
  };

  const confirmRemoveSession = (session) => {
    setSessionToRemove(session);
    setShowSessionConfirm(true);
  };

  const handleConfirm = () => {
    if (dayIndexToRemove !== null) {
      handleRemoveRow(dayIndexToRemove);
      setDayIndexToRemove(null);
    }
    setShowConfirm(false);
  };

  const handleConfirmSessionRemoval = () => {
    if (sessionToRemove) {
      // Find the corresponding session in customSessions if it exists
      const sessionName = sessionToRemove.replace(/_/g, " ");
      const session = customSessions.find(s =>
        s.name.toLowerCase() === sessionName.toLowerCase()
      );

      if (session) {
        // If it's in customSessions, use the ID from there
        handleRemoveSession(session.id);
      } else {
        // For default sessions, we'll need to create a temporary ID or use the session key
        // This assumes handleRemoveSession can handle string IDs or session keys
        handleRemoveSession(sessionToRemove);
      }
    }
    setSessionToRemove(null);
    setShowSessionConfirm(false);
  };

  const handleCancel = () => {
    setDayIndexToRemove(null);
    setShowConfirm(false);
  };

  const handleCancelSessionRemoval = () => {
    setSessionToRemove(null);
    setShowSessionConfirm(false);
  };

  const ConfirmationModal = () => {
    if (!showConfirm) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg w-80">
          <h3 className="text-lg font-semibold mb-4">Confirm Removal</h3>
          <p className="mb-6">Are you sure you want to remove this meal?</p>
          <div className="flex justify-end gap-2">
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );
  };

  const SessionConfirmationModal = () => {
    if (!showSessionConfirm) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg w-80">
          <h3 className="text-lg font-semibold mb-4">Confirm Session Removal</h3>
          <p className="mb-6">Are you sure you want to remove{" "}
            <strong>
              {sessionToRemove
                ?.replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </strong>{" "} session?</p>
          <div className="flex justify-end gap-2">
            <button
              onClick={handleCancelSessionRemoval}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmSessionRemoval}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );
  };

  const allSessions = mealData.length > 0
    ? Object.keys(mealData[0].meals)
    : [];

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-sm text-left border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 border">Date</th>
            {allSessions.map((session) => (
              <th key={session} className="px-6 py-3 border capitalize relative">
                {session.replace(/_/g, " ")}
                <button
                  onClick={() => confirmRemoveSession(session)}
                  className="ml-2 text--500 hover:text-red-600 absolute right-2 top-3"
                  title="Remove session"
                >
                  ✖
                </button>
              </th>
            ))}
            <th className="px-6 py-3 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {mealData.map((dayData, dayIndex) => (
            <tr key={dayIndex} className="border-b hover:bg-gray-50">
              <td className="px-6 py-4 border font-medium">
                <input
                  type="date"
                  value={dayData.date}
                  onChange={(e) => handleDateChange(dayIndex, e.target.value)}
                  className="border rounded p-1 w-full"
                  min={isEditMode ? from_date : formData.start_date || undefined}
                  max={isEditMode ? to_date : formData.end_date || undefined}
                  disabled={(!isEditMode ? !formData.start_date : !from_date) || (!isEditMode ? !formData.end_date : !to_date)}
                />
              </td>
              {allSessions.map((session) => {
                const formattedSession = session.replace(/_/g, " ");
                return (
                  <td key={session} className="px-6 py-4 border">
                    {renderMealItems(dayData.meals[session] || [], dayIndex, formattedSession)}
                    <button
                      onClick={() => handleAddItem(dayIndex, session)}
                      className="mt-2 text-blue-500 hover:text-blue-700"
                    >
                      + Add Item
                    </button>
                  </td>
                )
              })}
              <td className="px-6 py-4 border">
                <div className="flex flex-col items-center gap-2 w-full">
                  <button
                    onClick={() => handleCopyRow(dayIndex)}
                    className="bg-blue-400 text-white px-3 py-1 w-full rounded hover:bg-blue-500 transition"
                  >
                    Copy
                  </button>
                  <button
                    onClick={() => confirmRemove(dayIndex)}
                    className="bg-red-400 text-white px-3 py-1 w-full rounded hover:bg-red-500 transition"
                  >
                    Remove
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmationModal />
      <SessionConfirmationModal />
    </div>
  );
};

const SessionSelectionModal = ({
  isOpen,
  onClose,
  onConfirm,
  availableSessions,
  selectedSessions,
  handleSessionSelect
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Select Sessions</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="max-h-96 overflow-y-auto mb-4">
          {availableSessions.length > 0 ? (
            <ul className="divide-y divide-gray-200">
              {availableSessions.map((session) => (
                <li key={session.id} className="py-2">
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedSessions.includes(session.id)}
                      onChange={() => handleSessionSelect(session.id)}
                      className="rounded text-blue-500 focus:ring-blue-500 h-4 w-4"
                    />
                    <span>{session.name || `Session ${session.id}`}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No sessions available</p>
          )}
        </div>

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

const AddItemModal = ({
  isOpen,
  onClose,
  onAdd,
  categories,
  availableItems,
  setAvailableItems,
  selectedCategory,
  setSelectedCategory,
  fetchItemsByCategory,
  bowlList,
  baseUrl,
  mealType, // Breakfast, Lunch, or Dinner
  sessionList,
  methodList,
  rows,
  setRows,
  maharajList,
  formData,
  selectedCustomSessions
}) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [maharajId, setMaharajId] = useState('');
  const [selectedMeasurements, setSelectedMeasurements] = useState([]);
  const [selectedBowl, setSelectedBowl] = useState('');
  const [trackForReport, setTrackForReport] = useState(false);
  const [itemDetails, setItemDetails] = useState({
    fg_consumption_per_person_kgs: '',
    fg_consumption_per_person_ltr: '',
    fg_consumption_per_person_nos: '',
    avg_density: '',
    avg_qty_in_1_bowl_ltr: '',
    avg_qty_in_1_bowl_kgs: '',
    avg_qty_in_1_bowl_nos: ''
  });

  //Get session based on meal type
  const getSessionId = () => {
    const session = sessionList.find(session =>
      session.name.toLowerCase() === mealType
    );
    console.log("Session:", session)
    return session ? session.id : null; // Return ID if found, otherwise null
  };

  useEffect(() => {
    // Reset measurements when item changes
    setSelectedMeasurements([]);
    setItemDetails({
      fg_consumption_per_person_kgs: '',
      fg_consumption_per_person_ltr: '',
      fg_consumption_per_person_nos: '',
      avg_density: '',
      avg_qty_in_1_bowl_ltr: '',
      avg_qty_in_1_bowl_kgs: '',
      avg_qty_in_1_bowl_nos: ''
    });
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem && selectedBowl && selectedMeasurements.length > 0) {
      fetchItemData();
      callApi();
    }
  }, [selectedItem, selectedBowl, selectedMeasurements]);

  const fetchItemData = async () => {
    if (!selectedItem || !selectedMeasurements.length) return;
    const sessionId = getSessionId();

    try {
      //let avg_qty_in_1_bowl_ltr = '';
      let avg_density;
      // Get bowl item first as it's needed for multiple calculations
      const bowlItem = bowlList.find(v => v.id.toString() === selectedBowl);

      // Set initial avg_qty_in_1_bowl_ltr from bowl marked volume
      const avg_qty_in_1_bowl_ltr = bowlItem?.bowl_marked_volume ?? 0;

      // Fetch consumption data if weight/volume measurements selected
      if (selectedMeasurements.includes(1) || selectedMeasurements.includes(4)) {
        const condition = `menu_item_id = '${selectedItem}' AND method = '1'`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        const data = response.data.data?.[0];

        if (data) {
          const fg_consumption_per_person_kgs = data.fg_consumption_per_person ?? '';
          const avg_density = data.avg_density ?? '';
          const bowlItem = bowlList.find(v => v.id.toString() === selectedBowl);
          const avg_qty_in_1_bowl_kgs = ((bowlItem?.bowl_marked_volume ?? 0) * avg_density).toFixed(2);

          setItemDetails(prev => ({
            ...prev,
            fg_consumption_per_person_kgs,
            avg_density,
            avg_qty_in_1_bowl_kgs
          }));
        }
      }

      if (selectedMeasurements.includes(2) || selectedMeasurements.includes(5)) {
        const condition = `menu_item_id = '${selectedItem}' AND (method = '2')`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        const data = response.data.data?.[0];
        if (data) {
          const avg_density = data.avg_density ?? '';
          const fg_consumption_per_person_ltr = avg_density > 0 ? (itemDetails.fg_consumption_per_person_kgs / avg_density).toFixed(2) : 0;
          //const bowlItem = bowlList.find(v => v.id.toString() === selectedBowl);
          //const avg_qty_in_1_bowl_ltr = bowlItem?.bowl_marked_volume ?? 0;

          setItemDetails(prev => ({
            ...prev,
            avg_density,
            fg_consumption_per_person_ltr,
            avg_qty_in_1_bowl_ltr,
          }));
        }
      }

      // Fetch consumption data if count measurements selected
      if (selectedMeasurements.includes(3) || selectedMeasurements.includes(6)) {
        const condition = `menu_item_id = '${selectedItem}' AND (method = '3')`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        const data = response.data.data?.[0];

        if (data) {
          const fg_consumption_per_person_nos = data.fg_consumption_per_person ?? '';

          // Calculate avg_qty_in_1_bowl_nos if necessary
          let avg_qty_in_1_bowl_nos = 0;
          if ((selectedMeasurements.includes(3) || selectedMeasurements.includes(6))) {
            try {
              const method = selectedMeasurements.includes(3) ? 3 : 6;
              const currentDate = new Date().toISOString().split('T')[0]; // Using current date as fallback
              const condition = `menu_item_id = '${selectedItem}' AND session = '${sessionId}' AND method = '${method}'`;

              const response = await axios.get(
                `${baseUrl}/comman/list?table=finished_goods&condition=${condition}`
              );

              const fgData = response.data.data || [];
              if (fgData.length > 0) {
                const weight_1_filled_bowl = fgData.reduce((acc, curr) => {
                  return acc + Number(curr.weight_of_1_filled_bowl);
                }, 0);

                const finished_roti_weight = fgData.reduce((acc, curr) => {
                  return acc + Number(curr.avg_finished_roti_weight);
                }, 0);

                const bowlItem = bowlList.find(v => v.id.toString() === selectedBowl);
                const bowl_weight = Number(bowlItem?.bowl_weight_empty ?? 0);

                avg_qty_in_1_bowl_nos = (weight_1_filled_bowl - bowl_weight) / (finished_roti_weight * 1000);

                if (isNaN(avg_qty_in_1_bowl_nos) || !isFinite(avg_qty_in_1_bowl_nos)) {
                  avg_qty_in_1_bowl_nos = 0;
                } else {
                  avg_qty_in_1_bowl_nos = Number(avg_qty_in_1_bowl_nos).toFixed(2);
                }
              }
            } catch (error) {
              console.error("Error calculating qty in bowl:", error);
              avg_qty_in_1_bowl_nos = 0;
            }
          }

          setItemDetails(prev => ({
            ...prev,
            fg_consumption_per_person_nos,
            avg_qty_in_1_bowl_nos
          }));
        }
      }

      let density, avg_qty_in_1_bowl_kgs;
      if ((selectedMeasurements.includes(2) || selectedMeasurements.includes(5)) && (selectedMeasurements.includes(1) || selectedMeasurements.includes(4))) {

        const response = await axios.get(`${baseUrl}/reports/master_report?fromDate=${formData.start_date}&toDate=${formData.end_date}&session=${sessionId}&item=${JSON.stringify([{ value: selectedItem, label: "" }])}`);
        const masterDetail = response.data.data ?? [];
        if (masterDetail.length > 0) {
          const std_density = masterDetail[0].std_density ?? 0;
          const live_density = masterDetail[0].density ?? 0;
          density = live_density != 0 ? live_density : (std_density != 0 ? std_density : 0);
        } else {
          density = 1;
        }
      } else {
        density = 1;
      }
      avg_density = density;
      avg_qty_in_1_bowl_kgs = (avg_qty_in_1_bowl_ltr * density).toFixed(3);

      setItemDetails(prev => ({
        ...prev,
        avg_qty_in_1_bowl_ltr,

      }));

    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  const handleRefresh = async () => {
    if (!rows) return;

    //setLoading(true);
    console.log("working")

    let fg_consumption_per_person_kgs;
    let avg_density;
    let fg_consumption_per_person_ltr;

    if (selectedMeasurements.includes(1)) {
      const condition = `menu_item_id = '${selectedItem}' AND method = '1'`;
      const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
      fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
      avg_density = response.data.data?.[0]?.avg_density ?? '';
    }

    if (selectedMeasurements.includes(4)) {
      const condition = `menu_item_id = '${selectedItem}' AND method = '4'`;
      const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
      console.log("response: ", response.data.data)
      fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
      avg_density = response.data.data?.[0]?.avg_density ?? '';
    }

    if (selectedMeasurements.includes(2) || selectedMeasurements.includes(5)) {
      fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
    }

    let fg_consumption_per_person_nos;
    if (selectedMeasurements.includes(3)) {
      const condition = `menu_item_id = '${selectedItem}' AND method = '3'`;
      const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
      fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
      avg_density = response.data.data?.[0]?.avg_density ?? '';
    }

    if (selectedMeasurements.includes(6)) {
      const condition = `menu_item_id = '${selectedItem}' AND method = '6'`;
      const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
      fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
      avg_density = response.data.data?.[0]?.avg_density ?? '';
    }
    setItemDetails(prev => ({
      ...prev,
      fg_consumption_per_person_kgs,
      fg_consumption_per_person_ltr,
      fg_consumption_per_person_nos,
      avg_density,
    }));
  };
  const handleMeasurementChange = (methodId, isChecked) => {
    setSelectedMeasurements(prev => {
      const updatedMeasurements = isChecked
        ? [...prev, methodId]  // Add if checked
        : prev.filter(id => id !== methodId); // Remove if unchecked

      if (selectedItem) {
        callApi();
      }

      return updatedMeasurements;
    });
  };

  //console.log("item Details: ", itemDetails);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemDetails(prev => {
      const updatedDetails = { ...prev, [name]: value };

      // Calculate related fields based on density and measurement type
      if (name === 'fg_consumption_per_person_kgs') {
        const avg_density = updatedDetails.avg_density || 0;
        if (avg_density > 0) {
          updatedDetails.fg_consumption_per_person_ltr = (value / avg_density).toFixed(3);
          console.log("fg_consumption_per_person_ltr", updatedDetails.fg_consumption_per_person_ltr)
        }
      } else if (name === 'fg_consumption_per_person_ltr') {
        const avg_density = updatedDetails.avg_density || 0;
        if (avg_density > 0) {
          updatedDetails.fg_consumption_per_person_kgs = (value * avg_density).toFixed(3);
          console.log("fg_consumption_per_person_ltr", updatedDetails.fg_consumption_per_person_kgs)
        }
      } else if (name === 'avg_density') {
        const fg_consumption_per_person_ltr = updatedDetails.fg_consumption_per_person_ltr || 0;
        const avg_qty_in_1_bowl_ltr = updatedDetails.avg_qty_in_1_bowl_ltr || 0;

        if (value > 0) {
          updatedDetails.fg_consumption_per_person_kgs = (fg_consumption_per_person_ltr * value).toFixed(3);
          updatedDetails.avg_qty_in_1_bowl_kgs = (avg_qty_in_1_bowl_ltr * value).toFixed(3);
        }
      } else if (name === 'avg_qty_in_1_bowl_kgs') {
        const avg_density = updatedDetails.avg_density || 0;
        if (avg_density > 0) {
          updatedDetails.avg_qty_in_1_bowl_ltr = (value / avg_density).toFixed(3);
        }
      } else if (name === 'avg_qty_in_1_bowl_ltr') {
        // const avg_qty_in_1_bowl_ltr = updatedRow.avg_qty_in_1_bowl_ltr || 0;
        const avg_density = updatedDetails.avg_density || 0;
        if (avg_density > 0) {
          updatedDetails.avg_qty_in_1_bowl_kgs = (value * avg_density).toFixed(3);
          console.log("updateDetails:", updatedDetails)
        }
      }
      return updatedDetails;

    });
  };

  // Add this function inside your AddItemModal component
  const callApi = async () => {
    if (!selectedItem || !selectedMeasurements.length) return;

    try {
      // Handle Weight/Volume measurements (Method 1 or 4)
      if ((selectedMeasurements.includes(1) || selectedMeasurements.includes(4)) && (!itemDetails.fg_consumption_per_person_kgs || itemDetails.fg_consumption_per_person_kgs === '')) {
        const condition = `menu_item_id = '${selectedItem}' AND method = '1'`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);

        const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        const avg_density = response.data.data?.[0]?.avg_density ?? '';
        const fg_consumption_per_person_ltr = avg_density > 0 ? (value / avg_density).toFixed(2) : 0;

        const bowlItem = bowlList.find(v => v.id.toString() === selectedBowl);
        const avg_qty_in_1_bowl_ltr = bowlItem?.bowl_marked_volume ?? 0;
        const avg_qty_in_1_bowl_kgs = ((bowlItem?.bowl_marked_volume ?? 0) * avg_density).toFixed(2);

        setItemDetails(prev => ({
          ...prev,
          fg_consumption_per_person_kgs: value,
          avg_density: avg_density,
          fg_consumption_per_person_ltr: fg_consumption_per_person_ltr,
          avg_qty_in_1_bowl_kgs: avg_qty_in_1_bowl_kgs,
          avg_qty_in_1_bowl_ltr: avg_qty_in_1_bowl_ltr
        }));
      }

      // Handle Count measurements (Method 3 or 6)
      if ((selectedMeasurements.includes(3) || selectedMeasurements.includes(6))) {
        const condition = `menu_item_id = '${selectedItem}' AND (method = '3' OR method = '6')`;
        const response = await axios.get(`${baseUrl}/comman/list?table=consumption_master&condition=${condition}`);

        const value = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        const avg_density = '1.000';

        setItemDetails(prev => ({
          ...prev,
          fg_consumption_per_person_nos: value,
          avg_density: avg_density
        }));
      }

    } catch (error) {
      console.error("Error in callApi:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg w-max max-w-4xl max-h-[90vh] overflow-y-auto">
        <h3 className="text-lg font-semibold mb-4">Add {mealType.charAt(0).toUpperCase() + mealType.slice(1)} Item</h3>

        <div className="space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
            {/* Category Selection */}
            <div className="col-span-1">
              <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">
                Menuitem Category
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    if (e.target.value) {
                      fetchItemsByCategory(e.target.value);
                    } else {
                      setAvailableItems([]);
                    }
                    setSelectedItem('');
                  }}
                >
                  <option value="">Select a category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name} ({category.eng_name})
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Item Selection */}
            <div className="col-span-1">
              <label htmlFor="menu_item_id" className="block text-sm font-medium leading-6 text-gray-900">
                Menuitem
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                >
                  <option value="">Select an item</option>
                  {availableItems.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.item_name} ({item.eng_name || ''})
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Maharaj Selection - assuming you need this from reference file */}
            {/* <div className="col-span-1">
              <label htmlFor="maharaj_id" className="block text-sm font-medium leading-6 text-gray-900">
                Maharaj Name
              </label>
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  value={maharajId}
                  onChange={(e) => setMaharajId(e.target.value)}
                >
                  <option value="">Select a maharaj</option>
                  {maharajList && maharajList.map(maharaj => (
                    <option key={maharaj.id} value={maharaj.id}>
                      {maharaj.maharaj_name}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}

            {/* Bowl Selection */}
            {/* <div className="col-span-1">
              <label htmlFor="event_bowl_type" className="block text-sm font-medium leading-6 text-gray-900">
                Event Bowl Type
              </label>
              <div className="mt-2 flex items-end gap-2"> */}
            {/* <div className="flex-grow">
                  <select
                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    value={selectedBowl}
                    onChange={(e) => setSelectedBowl(e.target.value)}
                  >
                    <option value="">Select a bowl</option>
                    {bowlList.map(bowl => (
                      <option key={bowl.id} value={bowl.id}>
                        {bowl.name}
                      </option>
                    ))}
                  </select>
                </div> */}


            {/* {selectedItem && selectedMeasurements.length > 0 && ( */}
            {/* // <div className="flex justify-end"> */}
            {/* <button
                  onClick={handleRefresh}
                  className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Get Consumption
                </button>
              </div> */}
            {/* )} */}
            {/* </div> */}
          </div>

          {/* Measurement Methods */}
          {/* <div className="col-span-2">
            <label htmlFor="measurement" className="block text-sm font-medium leading-6 text-gray-900">
              Measurement
            </label>
            <div className="grid grid-cols-3">
              {methodList.map((method) => (
                <div key={method.id}>
                  <label>
                    <input
                      type="checkbox"
                      name={method.name}
                      className="mr-2"
                      checked={selectedMeasurements.includes(method.id)}
                      onChange={(e) => handleMeasurementChange(method.id, e.target.checked)}
                    />
                    {method.name}
                  </label>
                </div>
              ))}
            </div>
          </div> */}

          {/* Track for Report */}
          {/* <div className="mt-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={trackForReport}
                onChange={(e) => setTrackForReport(e.target.checked)}
              />
              <span>Track for Report</span>
            </label>
          </div> */}


          {/* Consumption Values */}
          {/* <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-5 gap-y-4">
            {(selectedMeasurements.includes(1) || selectedMeasurements.includes(4)) && (
              <div className="col-span-1">
                <label htmlFor="fg_consumption_per_person_kgs" className="block text-sm font-medium leading-6 text-gray-900">
                  Fg Consumption per person (kgs)
                </label>
                <div className="mt-2">
                  <input
                    id="fg_consumption_per_person_kgs"
                    name="fg_consumption_per_person_kgs"
                    type="number"
                    step="0.01"
                    placeholder="Fg Consumption per person (kgs)"
                    value={itemDetails.fg_consumption_per_person_kgs}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                    className={`${!itemDetails.fg_consumption_per_person_kgs || parseFloat(itemDetails.fg_consumption_per_person_kgs) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
            )}

            {(selectedMeasurements.includes(2) || selectedMeasurements.includes(5)) && (
              <div className="col-span-1">
                <label htmlFor="fg_consumption_per_person_ltr" className="block text-sm font-medium leading-6 text-gray-900">
                  Fg Consumption per person (Ltr)
                </label>
                <div className="mt-2">
                  <input
                    id="fg_consumption_per_person_ltr"
                    name="fg_consumption_per_person_ltr"
                    type="number"
                    step="0.01"
                    placeholder="Fg Consumption per person (ltr)"
                    value={itemDetails.fg_consumption_per_person_ltr}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                    className={`${!itemDetails.fg_consumption_per_person_ltr || parseFloat(itemDetails.fg_consumption_per_person_ltr) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
            )}

            {(selectedMeasurements.includes(3) || selectedMeasurements.includes(6)) && (
              <div className="col-span-1">
                <label htmlFor="fg_consumption_per_person_nos" className="block text-sm font-medium leading-6 text-gray-900">
                  Fg Consumption per person (nos)
                </label>
                <div className="mt-2">
                  <input
                    id="fg_consumption_per_person_nos"
                    name="fg_consumption_per_person_nos"
                    type="number"
                    step="0.01"
                    placeholder="Fg Consumption per person (nos)"
                    value={itemDetails.fg_consumption_per_person_nos}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                    className={`${!itemDetails.fg_consumption_per_person_nos || parseFloat(itemDetails.fg_consumption_per_person_nos) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
            )}

            <div className="col-span-1">
              <label htmlFor="avg_density" className="block text-sm font-medium leading-6 text-gray-900">
                Avg Density
              </label>
              <div className="mt-2">
                <input
                  id="avg_density"
                  name="avg_density"
                  type="number"
                  step="0.01"
                  placeholder="Avg Density"
                  value={itemDetails.avg_density}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                  className={`${!itemDetails.avg_density || parseFloat(itemDetails.avg_density) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
            </div>

            {(selectedMeasurements.includes(1) || selectedMeasurements.includes(4)) && (
              <div className="col-span-1">
                <label htmlFor="avg_qty_in_1_bowl_kgs" className="block text-sm font-medium leading-6 text-gray-900">
                  Avg Qty in 1 Bowl(Kgs)
                </label>
                <div className="mt-2">
                  <input
                    id="avg_qty_in_1_bowl_kgs"
                    name="avg_qty_in_1_bowl_kgs"
                    type="number"
                    step="0.01"
                    placeholder="Avg Qty In 1 Bowl Kgs"
                    value={itemDetails.avg_qty_in_1_bowl_kgs}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                    className={`${!itemDetails.avg_qty_in_1_bowl_kgs || parseFloat(itemDetails.avg_qty_in_1_bowl_kgs) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
            )}

            {(selectedMeasurements.includes(2) || selectedMeasurements.includes(5)) && (
              <div className="col-span-1">
                <label htmlFor="avg_qty_in_1_bowl_ltr" className="block text-sm font-medium leading-6 text-gray-900">
                  Avg Qty in 1 Bowl(Ltr)
                </label>
                <div className="mt-2">
                  <input
                    id="avg_qty_in_1_bowl_ltr"
                    name="avg_qty_in_1_bowl_ltr"
                    type="number"
                    step="0.01"
                    placeholder="Avg Qty In 1 Bowl Ltr"
                    value={itemDetails.avg_qty_in_1_bowl_ltr}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                    className={`${!itemDetails.avg_qty_in_1_bowl_ltr || parseFloat(itemDetails.avg_qty_in_1_bowl_ltr) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
            )}

            {(selectedMeasurements.includes(3) || selectedMeasurements.includes(6)) && (
              <div className="col-span-1">
                <label htmlFor="avg_qty_in_1_bowl_nos" className="block text-sm font-medium leading-6 text-gray-900">
                  Avg Qty in 1 Bowl(Nos)
                </label>
                <div className="mt-2">
                  <input
                    id="avg_qty_in_1_bowl_nos"
                    name="avg_qty_in_1_bowl_nos"
                    type="number"
                    step="0.01"
                    placeholder="Avg Qty In 1 Bowl nos"
                    value={itemDetails.avg_qty_in_1_bowl_nos}
                    onChange={handleInputChange}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => { if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); } }}
                    className={`${!itemDetails.avg_qty_in_1_bowl_nos || parseFloat(itemDetails.avg_qty_in_1_bowl_nos) === 0 ? 'bg-orange-50' : ''} block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>
            )}
          </div> */}
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end gap-x-4">
          <button
            onClick={() => {
              setSelectedCategory('');
              setSelectedItem('');
              setSelectedMeasurements([]);
              setSelectedBowl('');
              setMaharajId('');
              setTrackForReport('');
              onClose();
            }}
            className="rounded-md px-3 py-2 text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (selectedItem) {
                const item = availableItems.find(i => i.id === parseInt(selectedItem));
                const bowlItem = bowlList.find(b => b.id.toString() === selectedBowl);
                const sessionId = getSessionId();

                // Format the menu item with all the necessary data
                const menuItemData = {
                  ...item,
                  menuitem_category: selectedCategory,
                  maharaj_id: maharajId,
                  menu_item_id: {
                    value: item.id,
                    label: `${item.item_name} (${item.eng_name || ''})`
                  },
                  measurements: selectedMeasurements.length > 0 ? selectedMeasurements.join(',') : null,
                  fg_consumption_per_person_kgs: itemDetails.fg_consumption_per_person_kgs,
                  fg_consumption_per_person_ltr: itemDetails.fg_consumption_per_person_ltr,
                  fg_consumption_per_person_nos: itemDetails.fg_consumption_per_person_nos,
                  avg_density: itemDetails.avg_density,
                  event_bowl_type: {
                    value: bowlItem?.id,
                    label: bowlItem?.name
                  },
                  avg_qty_in_1_bowl_ltr: itemDetails.avg_qty_in_1_bowl_ltr,
                  avg_qty_in_1_bowl_kgs: itemDetails.avg_qty_in_1_bowl_kgs,
                  avg_qty_in_1_bowl_nos: itemDetails.avg_qty_in_1_bowl_nos,
                  track_for_report: trackForReport,
                  session: sessionId,
                  meal_type: mealType
                };

                onAdd(menuItemData);
                setSelectedCategory('');
                setSelectedItem('');
                setSelectedMeasurements([]);
                setSelectedBowl('');
                setMaharajId('');
                setTrackForReport(false);
                onClose();
              }
            }}
            className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={!selectedItem}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const MenuCreationForm = ({ details }) => {
  const location = useLocation()
  const { eventName, from_date, to_date } = location.state || {};
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);

  const handleExportPdf = () => {
    if (!details.mealData || details.mealData.length === 0) {
      console.log("No data to export");
      return;
    }

    // Determine event name and dates based on mode
    const event_name = details.isEditMode
      ? eventName
      : details.formData.event_name || "Event";

    const startDate = details.isEditMode
      ? (from_date ? new Date(from_date).toLocaleDateString("en-GB") : "")
      : (details.formData.start_date ? new Date(details.formData.start_date).toLocaleDateString("en-GB") : "");

    const endDate = details.isEditMode
      ? (to_date ? new Date(to_date).toLocaleDateString("en-GB") : "")
      : (details.formData.end_date ? new Date(details.formData.end_date).toLocaleDateString("en-GB") : "");

    // Formatting event details for title
    const eventTitle = `${event_name} General Kitchen Menu`;
    const eventSubTitle = `Shibir Dates : ${startDate} - ${endDate}`;

    const doc = new jsPDF();
    doc.addFont(gujaratiFont, "Gujarati", "normal");
    doc.setFont("Gujarati");

    // Add centered title with event details
    doc.setFontSize(16);
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(eventTitle, pageWidth / 2, 15, { align: "center" });
    doc.text(eventSubTitle, pageWidth / 2, 25, { align: "center" });

    // Extract all session names dynamically
    const allSessions = details.mealData.length > 0 ? Object.keys(details.mealData[0].meals) : [];

    // Construct table headers dynamically
    const tableColumn = ["Date", ...allSessions.map(session => session.charAt(0).toUpperCase() + session.slice(1))];

    // Prepare table data dynamically
    const tableRows = details.mealData.map(dayData => {
      const dateObj = new Date(dayData.date);
      const formattedDate = `${dateObj.getDate()}-${dateObj.toLocaleString("en-GB", { month: "short" })}-${dateObj.getFullYear()}`;
      const dayName = dateObj.toLocaleDateString("en-GB", { weekday: "short" });
      const fullDate = `${formattedDate}, ${dayName}`;
      // Function to format meal items
      const formatMealItems = (items) => {
        if (!items || items.length === 0) return "";
        return items.map(item => item.item_name).join(", ");
      };

      // Generate row data dynamically based on available sessions
      return [fullDate, ...allSessions.map(session => formatMealItems(dayData.meals[session]))];
    });

    // Add table to PDF with font configuration
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      styles: {
        fontSize: 10,
        cellPadding: 4,
        font: "Gujarati",
        overflow: "linebreak",
        cellWidth: "wrap",
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Date column
        ...Object.fromEntries(allSessions.map((_, index) => [index + 1, { cellWidth: "auto" }])), // Dynamic session columns
      },
    });

    // Save PDF
    doc.save(`${event_name}_MenuData.pdf`);
    console.log("Export PDF");
  };

  const handleExportExcel = () => {
    if (!details.mealData || details.mealData.length === 0) {
      console.log("No data to export");
      return;
    }

    // Determine event name and dates based on mode
    const event_name = details.isEditMode
      ? eventName
      : details.formData.event_name || "Event";

    const startDate = details.isEditMode
      ? (from_date ? new Date(from_date).toLocaleDateString("en-GB") : "")
      : (details.formData.start_date ? new Date(details.formData.start_date).toLocaleDateString("en-GB") : "");

    const endDate = details.isEditMode
      ? (to_date ? new Date(to_date).toLocaleDateString("en-GB") : "")
      : (details.formData.end_date ? new Date(details.formData.end_date).toLocaleDateString("en-GB") : "");

    const formattedTitle = `${event_name} General Kitchen Menu \nShibir Dates : ${startDate} - ${endDate}`;

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Add title at the top
    XLSX.utils.sheet_add_aoa(worksheet, [[formattedTitle]], { origin: "A1" });

    // Extract all session names dynamically
    const allSessions = details.mealData.length > 0 ? Object.keys(details.mealData[0].meals) : [];

    // Construct table headers dynamically
    const tableColumn = ["Date", ...allSessions.map(session => session.charAt(0).toUpperCase() + session.slice(1))];
    XLSX.utils.sheet_add_aoa(worksheet, [tableColumn], { origin: "A3" });

    let rowIndex = 4; // Start data from row 4

    details.mealData.forEach(dayData => {
      const dateObj = new Date(dayData.date);
      const formattedDate = `${dateObj.getDate()}-${dateObj.toLocaleString("en-GB", { month: "short" })}-${dateObj.getFullYear()}`;
      const dayName = dateObj.toLocaleDateString("en-GB", { weekday: "short" });
      const date = new Date(dayData.date).toLocaleDateString("en-GB");
      // const dayName = new Date(dayData.date).toLocaleDateString("en-GB", { weekday: "short" }); // Short weekday name (Mon, Tue, etc.)

      const fullDate = `${formattedDate}, ${dayName}`;
      // Function to format meal items
      const formatMealItems = (items) => {
        if (!items || items.length === 0) return "";
        return items.map(item => item.item_name).join(", ");
      };

      // Generate row data dynamically based on available sessions
      const rowData = [fullDate, ...allSessions.map(session => formatMealItems(dayData.meals[session]))];

      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` });
      rowIndex++;
    });

    // Set column widths for readability
    worksheet["!cols"] = [
      { wch: 12 }, // Date column
      ...allSessions.map(() => ({ wch: 40 })) // Dynamic session columns
    ];

    // Merge title cells across all columns
    worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: allSessions.length } }];

    // Set row height for header and data rows
    if (!worksheet["!rows"]) worksheet["!rows"] = [];
    worksheet["!rows"][0] = { hpt: 30 }; // Title row
    worksheet["!rows"][2] = { hpt: 20 }; // Header row

    // Set taller row height for data rows
    for (let r = 3; r < rowIndex; r++) {
      worksheet["!rows"][r] = { hpt: 60 }; // Ensuring wrapped text
    }

    // Download the file
    XLSX.utils.book_append_sheet(workbook, worksheet, "Menu Data");
    XLSX.writeFile(workbook, `${event_name}_MenuData.xlsx`);
  };

  const handleFilterSessions = () => {
    details.setShowSessionModal(true);
  };

  const handleSessionSelect = (sessionId) => {
    setSelectedSessionIds((prev) => {
      if (prev.includes(sessionId)) {
        return prev.filter((id) => id !== sessionId);
      } else {
        return [...prev, sessionId];
      }
    });

    details.setSelectedCustomSessions((prev) => {
      if (prev.includes(sessionId)) {
        return prev.filter((id) => id !== sessionId);
      } else {
        return [...prev, sessionId];
      }
    });

  };

  const handleAddSessionsConfirm = () => {
    details.handleAddSession(selectedSessionIds);
    details.setSelectedCustomSessions(selectedSessionIds);
    setSelectedSessionIds([]);
  };

  const handleCreateDuplicate = () => {
    // Create duplicate logic to be implemented
    console.log('Create Duplicate');
  };

  const handleRefresh = async () => {
    if (!details.rows) return;

    // details.setLoading(true);
    const newArray = await Promise.all(details.rows.map(async (item) => {
      const measurementsArray = item.measurements ?? [];
      let fg_consumption_per_person_kgs;
      let avg_density;
      let fg_consumption_per_person_ltr;

      if (measurementsArray.includes(1)) {
        const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '1'`;
        const response = await axios.get(`${details.baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        avg_density = response.data.data?.[0]?.avg_density ?? '';
      }

      if (measurementsArray.includes(4)) {
        const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '4'`;
        const response = await axios.get(`${details.baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        fg_consumption_per_person_kgs = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        avg_density = response.data.data?.[0]?.avg_density ?? '';
      }

      if (measurementsArray.includes(2) || measurementsArray.includes(5)) {
        fg_consumption_per_person_ltr = avg_density > 0 ? (fg_consumption_per_person_kgs / avg_density).toFixed(3) : 0;
      }

      let fg_consumption_per_person_nos;
      if (measurementsArray.includes(3)) {
        const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '3'`;
        const response = await axios.get(`${details.baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        avg_density = response.data.data?.[0]?.avg_density ?? '';
      }

      if (measurementsArray.includes(6)) {
        const condition = `menu_item_id = '${item.menu_item_id.value}' AND method = '6'`;
        const response = await axios.get(`${details.baseUrl}/comman/list?table=consumption_master&condition=${condition}`);
        fg_consumption_per_person_nos = response.data.data?.[0]?.fg_consumption_per_person ?? '';
        avg_density = response.data.data?.[0]?.avg_density ?? '';
      }

      return {
        ...item,
        fg_consumption_per_person_kgs,
        fg_consumption_per_person_ltr,
        fg_consumption_per_person_nos,
        avg_density,
      };
    }));

    details.setRows(newArray);
    // details.setLoading(false);
    window.location.reload();
  };

  return (
    <div className="p-4">
      <div className="flex justify-end items-center mb-6 gap-4">
        {/* Left group with Add Event, Refresh, and Create Duplicate buttons */}
        <div className="flex gap-2">
          <button
            onClick={() => window.open('/event_master/add', '_blank')}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200 mr-10"
          >
            Add Event
          </button>
          <button
            onClick={handleRefresh}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200 mr-10"
          >
            Refresh
          </button>
          <button
            onClick={details.handleSubmit}
            disabled={details.loading}
            // onClick={handleCreateDuplicate}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
          >
            {details.loading ? 'Processing...' : 'Save'}
            {/* Save */}
          </button>
        </div>

        <div className="w-5"></div>

        {/* Right group with Export buttons */}
        <div className="flex gap-2">
          <button
            onClick={handleExportExcel}
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-200"
          >
            Export Excel
          </button>
          <button
            onClick={handleExportPdf}
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
          >
            Export PDF
          </button>
        </div>
      </div>


      <h1 className="text-2xl font-bold mb-6">
        {details.isEditMode ? 'Edit Menu' : 'Duplicate Menu'}
      </h1>

      <EventSelectionForm
        formData={details.formData}
        validationErrors={details.validationErrors}
        handleInputChange={details.handleInputChange}
        eventList={details.eventList}
        isEditMode={details.isEditMode}
        fromDate={details.from_date}
        toDate={details.to_date}
      />

      {details.error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
          {details.error}
        </div>
      )}

      <div className="flex justify-end gap-2 mt-4 mb-4">
        <button
          onClick={handleFilterSessions}
          className="px-3 py-1.5 bg-gray-200 text-black rounded-md text-sm hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-400"
        >
          Add New Session
        </button>
        <button
          onClick={() => window.open('/session_master/list', '_blank')}
          className="px-3 py-1.5 bg-gray-200 text-black rounded-md text-sm hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-400"
        >
          Create Session
        </button>
      </div>

      <MealTable
        mealData={details.mealData}
        formData={details.formData}
        eventList={details.eventList}
        handleDateChange={details.handleDateChange}
        handleAddItem={details.handleAddItem}
        handleRemoveMenuItem={details.handleRemoveMenuItem}
        handleCopyRow={details.handleCopyRow}
        handleRemoveRow={details.handleRemoveRow}
        isEditMode={details.isEditMode}
        customSessions={details.customSessions}
        handleRemoveSession={details.handleRemoveSession}
      />

      <AddItemModal
        isOpen={details.showAddItemModal}
        onClose={() => details.setShowAddItemModal(false)}
        onAdd={details.handleItemAdd}
        categories={details.categories}
        availableItems={details.availableItems}
        selectedCategory={details.selectedCategory}
        setSelectedCategory={details.setSelectedCategory}
        fetchItemsByCategory={details.fetchItemsByCategory}
        setAvailableItems={details.setAvailableItems}
        bowlList={details.bowlList}
        baseUrl={details.baseUrl}
        mealType={details.selectedMealType}
        sessionList={details.sessionList}
        methodList={details.methodList}
        rows={details.rows}
        setRows={details.setRows}
        maharajList={details.maharajList}
        setMaharajList={details.setMaharajList}
        formData={details.formData}
        selectedCustomSessions={details.selectedCustomSessions}
      />

      {/* Add this right after your AddItemModal component */}
      <SessionSelectionModal
        isOpen={details.showSessionModal}
        onClose={() => details.setShowSessionModal(false)}
        onConfirm={handleAddSessionsConfirm}
        availableSessions={details.sessionList || []}
        selectedSessions={selectedSessionIds || []}
        handleSessionSelect={handleSessionSelect}
      />

      <div className="mt-4 flex gap-4">
        <button
          onClick={details.handleSubmit}
          disabled={details.loading}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200 disabled:bg-blue-300"
        >
          {details.loading ? 'Processing...' : 'Save'}
        </button>
        <button
          onClick={() => details.navigate('/menu_creation/view')}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:ring-gray-200"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default MenuCreationForm;

