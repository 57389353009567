import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../comman/config';
import Loader from '../../components/Loader';
import { MenuViewFilter } from '../../components/Filter/DateSessionItemFilter';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";
import gujaratiFont from '../../resources/NotoSansGujarati';

const MenuView = () => {
    const [loading, setLoading] = useState(false);
    const [mealData, setMealData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [allEvents, setAllEvents] = useState([]);
    const [allSessions, setAllSessions] = useState([]);

    // Initialize with empty values rather than null/undefined
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [session, setSession] = useState([]);
    const [item, setItem] = useState([]);

    // Standard meal sessions in order of appearance
    const standardSessions = ['breakfast', 'lunch', 'dinner'];

    useEffect(() => {
        // Initial load without filters
        fetchAllEvents();
    }, []);

    // Function to format date as DD/MM/YYYY
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Function to fetch all events with their date ranges
    const fetchAllEvents = async () => {
        try {
            const response = await axios.get(`${baseUrl}/event/list`);
            if (response.data.status && response.data.data) {
                setAllEvents(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    // Function to find all events that contain a specific date
    const findEventsForDate = (dateString) => {
        // Convert date string to date object for comparison
        const date = new Date(dateString);

        // Find all events where date falls between start_date and end_date
        const matchingEvents = allEvents.filter(event => {
            if (!event.start_date || !event.end_date) return false;

            const startDate = new Date(event.start_date);
            const endDate = new Date(event.end_date);

            return date >= startDate && date <= endDate;
        });

        return matchingEvents;
    };

    // Function to sort sessions with standard ones first
    const sortSessions = (sessions) => {
        // Start with standard sessions in the correct order
        const result = [...standardSessions].filter(session => sessions.includes(session));
        
        // Add any other sessions in alphabetical order
        const otherSessions = sessions.filter(session => !standardSessions.includes(session)).sort();
        
        return [...result, ...otherSessions];
    };

    const fetchMealData = async (fromDate, toDate, session, item, event) => {
        try {
            setLoading(true);
            setError(null);
            setPopupOpen(true);

            const sessionIds = session && session.length ? session.map(s => s.value).join(',') : '';
            const itemIds = item && item.length ? item.map(i => i.value).join(',') : '';
            const eventId = event && event.value !== 'all' ? event.value : '';

            if (eventId || fromDate || toDate) {
                const response = await axios.get(
                    `${baseUrl}/menu_creation/details?event_id=${eventId}&from_date=${fromDate}&to_date=${toDate}&session_ids=${sessionIds}&item_ids=${itemIds}&page=${page}`
                );

                if (response.data.status && response.data.data) {
                    const processedData = response.data.data.map(dayData => {
                        // Find all events that this date belongs to
                        const dateEvents = findEventsForDate(dayData.date);

                        // If we have matching events, store them with the day data
                        if (dateEvents.length > 0) {
                            dayData.events = dateEvents;
                        }

                        // Ensure all standard sessions exist
                        if (dayData.meals) {
                            standardSessions.forEach(session => {
                                if (!dayData.meals[session]) {
                                    dayData.meals[session] = [];
                                }
                            });
                        }

                        return dayData;
                    });
                    
                    setMealData(processedData);
                    
                    // Extract all unique session keys from the meals objects
                    const uniqueSessions = new Set();
                    processedData.forEach(dayData => {
                        if (dayData.meals) {
                            Object.keys(dayData.meals).forEach(session => {
                                uniqueSessions.add(session);
                            });
                        }
                    });

                    // Ensure standard sessions are included even if they don't exist in data
                    standardSessions.forEach(session => uniqueSessions.add(session));
                    
                    // Set sessions in proper order
                    setAllSessions(sortSessions(Array.from(uniqueSessions)));
                    
                    setTotalItem(response.data.totalItem || 0);
                    if (eventId && eventId !== 'all') {
                        setSelectedEvent({
                            id: eventId,
                            name: event.label
                        });
                    } else {
                        setSelectedEvent(null);
                    }
                } else {
                    setMealData([]);
                    setAllSessions(standardSessions);
                    setTotalItem(0);
                }
            } else {
                setMealData([]);
                setAllSessions(standardSessions);
                setTotalItem(0);
            }

            setLoading(false);
            setPopupOpen(false);
        } catch (error) {
            console.error('Error fetching meal data:', error);
            setError('Failed to load meal data');
            setLoading(false);
            setPopupOpen(false);
        }
    };

    // This matches the reference code's pattern more closely
    const handleFilterChange = (fromDate, toDate, session, item, event, eventImmediateUpdate = false) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setSession(session || []);
        setItem(item || []);

        // If this is an event change or immediate update is requested, fetch data immediately
        if (eventImmediateUpdate) {
            fetchMealData(fromDate, toDate, session, item, event);
        }
    };
    
    // New function to handle submit button click
    const handleSubmitFilters = () => {
        // Create event object based on the selectedEvent
        const eventObj = selectedEvent ?
            { value: selectedEvent.id, label: selectedEvent.name } :
            { value: 'all', label: 'All' };

        fetchMealData(fromDate, toDate, session, item, eventObj);
    };

    const handleExportPdf = () => {
        if (!mealData || mealData.length === 0) {
            console.log('No data to export');
            return;
        }

        const eventName = selectedEvent ? selectedEvent.name : "Event";
        const eventTitle = `${eventName} General Kitchen Menu  `;
        const eventSubTitle = `Shibir Dates : ${fromDate ? formatDate(fromDate) : ''} - ${toDate ? formatDate(toDate) : ''}`;

        const doc = new jsPDF();
        doc.addFont(gujaratiFont, 'Gujarati', 'normal');
        doc.setFont('Gujarati');

        // Add centered title with event details
        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.text(eventTitle, pageWidth / 2, 15, { align: "center" });
        doc.text(eventSubTitle, pageWidth / 2, 25, { align: "center" });

        // Create dynamic column headers with all sessions
        const tableColumn = ["Date", ...allSessions.map(session => 
            session.charAt(0).toUpperCase() + session.slice(1))];

        const tableRows = mealData.map(dayData => {

            const dateObj = new Date(dayData.date);
            //const formattedDate = dateObj.toLocaleDateString("en-GB");
            const formattedDate = `${dateObj.getDate()}-${dateObj.toLocaleString("en-GB", { month: "short" })}-${dateObj.getFullYear()}`;
            const dayName = dateObj.toLocaleDateString("en-GB", { weekday: "short" });
            const fullDate = `${formattedDate}, ${dayName}`;

            const row = [
                // (dayData.events && dayData.events.length > 0 ?
                //     dayData.events.map(e => e.event_name).join(', ') :
                //     (selectedEvent ? selectedEvent.name : "-")),
                    fullDate
            ];
            
            // Add each session's data
            allSessions.forEach(session => {
                const sessionItems = (dayData.meals[session] || []).map(item => item.item_name).join(', ');
                row.push(sessionItems || '-');
            });
            
            return row;
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            styles: {
                fontSize: 10,
                cellPadding: 4,
                font: 'Gujarati'
            },
        });

        doc.save(`${eventName}_MenuData_Report.pdf`);
        console.log('Exported PDF');
    };
    
    const handleExportExcel = () => {
        if (!mealData || mealData.length === 0) {
            console.log('No data to export');
            return;
        }

        const eventName = selectedEvent ? selectedEvent.name : "Event";
        const formattedTitle = `${eventName} General Kitchen Menu \nShibir Dates : ${fromDate ? formatDate(fromDate) : ''} - ${toDate ? formatDate(toDate) : ''}`;

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([]);

        // Add title at the top
        XLSX.utils.sheet_add_aoa(worksheet, [[formattedTitle]], { origin: "A1" });

        // Create dynamic column headers with all sessions
        const headers = ["Date", ...allSessions.map(session => 
            session.charAt(0).toUpperCase() + session.slice(1))];

        // Add table headers
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A3" });

        // Create rows with all meal items in a single cell
        let rowIndex = 4;

        mealData.forEach(dayData => {
            const dateObj = new Date(dayData.date);
            //const date = new Date(dayData.date).toLocaleDateString('en-GB');
            const formattedDate = `${dateObj.getDate()}-${dateObj.toLocaleString("en-GB", { month: "short" })}-${dateObj.getFullYear()}`;
            const dayName = new Date(dayData.date).toLocaleDateString("en-GB", { weekday: "short" });
            const fullDate = `${formattedDate}, ${dayName}`;
            // const event = 
            //     (dayData.events && dayData.events.length > 0 ?
            //         dayData.events.map(e => e.event_name).join(', ') :
            //         (selectedEvent ? selectedEvent.name : "-"));

            // Start with Event and Date
            //const rowData = [event, fullDate];
            const rowData = [fullDate];
            
            // Add each session's data
            allSessions.forEach(session => {
                const sessionItems = (dayData.meals[session] || []).map(item => item.item_name).join(', ');
                rowData.push(sessionItems || '-');
            });

            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` });
            rowIndex++;
        });

        // Set column widths for readability
        const colWidths = [
            // { wch: 30 },  // Event
            { wch: 12 }   // Date
        ];
        
        // Add width for all session columns
        allSessions.forEach(() => {
            colWidths.push({ wch: 40 }); // Session column width
        });
        
        worksheet['!cols'] = colWidths;

        // Merge title cells across all columns
        worksheet["!merges"] = [{ 
            s: { r: 0, c: 0 }, 
            e: { r: 0, c: headers.length - 1 } 
        }];

        // Set row height for header
        if (!worksheet['!rows']) worksheet['!rows'] = [];
        worksheet['!rows'][0] = { hpt: 30 }; // Title
        worksheet['!rows'][2] = { hpt: 20 }; // Header

        // Enable text wrapping for all data cells
        for (let row = 4; row < rowIndex; row++) {
            for (let col = 2; col < headers.length; col++) { // All session columns
                const cellRef = XLSX.utils.encode_cell({ r: row - 1, c: col });
                if (!worksheet[cellRef]) continue;

                if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
                worksheet[cellRef].s.alignment = { wrapText: true, vertical: 'top' };
            }
        }

        // Set larger row height for data rows to accommodate wrapped text
        for (let i = 3; i < rowIndex; i++) {
            worksheet['!rows'][i] = { hpt: 60 }; // Data rows
        }

        // Download the file
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Menu Data');
        XLSX.writeFile(workbook, `${eventName}_MenuData_Report.xlsx`);
    };

    const renderMealItems = (items) => {
        if (!items || items.length === 0) return "-";

        return (
            <div className="flex flex-wrap gap-2">
                {items.map((item, index) => (
                    <span
                        key={`${item.id}-${index}`}
                        className="bg-gray-200 px-2 py-1 rounded-full text-sm"
                        title={item.eng_name} // Show English name on hover
                    >
                        {item.item_name}
                    </span>
                ))}
            </div>
        );
    };

    // Render the events for a specific date
    const renderDateEvents = (dayData) => {
        // If no events property or empty events array
        if (!dayData.events || dayData.events.length === 0) {
            return dayData.event_name || '-';
        }
        
        // If there are events, render them
        return (
            <div className="flex flex-col gap-1">
                {dayData.events.map((event) => (
                    <span key={event.id} className="text-sm">
                        {event.event_name}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className="p-4">
            {loading && <Loader />}

            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-2xl font-bold">Menu Report</h1>
                <div>
                    <Link to="/">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow mb-6">
                <MenuViewFilter
                    handleSubmitFilters={handleSubmitFilters}
                    handleFilterChange={handleFilterChange}
                    handleExport={handleExportExcel}
                    handleExportPdf={handleExportPdf}
                />
            </div>

            {error && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
                    {error}
                </div>
            )}

            <div className="relative shadow-md sm:rounded-lg">
                <div className="overflow-x-auto">
                    {mealData.length > 0 ? (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3 border border-black text-center">Event</th>
                                    <th scope="col" className="px-6 py-3 border border-black text-center">Date</th>
                                    {allSessions.map(session => (
                                        <th key={session} scope="col" className="px-6 py-3 border border-black text-center">
                                            {session.charAt(0).toUpperCase() + session.slice(1)}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {mealData.map((dayData) => (
                                    <tr key={dayData.date} className="border-b hover:bg-gray-50 ">
                                        <td className="px-6 py-4 border border-black text-center font-medium">
                                            {renderDateEvents(dayData)}
                                        </td>
                                        <td className="px-6 py-4 border border-black text-center">
                                            {formatDate(dayData.date)}
                                        </td>
                                        {allSessions.map(session => (
                                            <td key={`${dayData.date}-${session}`} className="px-6 py-4 border border-black text-center">
                                                {renderMealItems(dayData.meals[session])}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center p-8 bg-gray-50 rounded-md">
                            Please select an event or date range to view menu data.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MenuView;
