import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import gujaratiFont from '../resources/NotoSansGujarati';

const OrderIngredientModal = ({
    showOrderModal,
    setShowOrderModal,
    ingredientList,
    clearItem,
    page
}) => {
    // If no ingredient list, return null
    if (!ingredientList) return null;

        // Determine if ingredientList is an array or single object
        const ingredients = Array.isArray(ingredientList)
        ? ingredientList
        : [ingredientList];

    const handleExportPDF = () => {
        if (ingredients.length === 0) return;

        // Create a new jsPDF instance
        const doc = new jsPDF();
        doc.addFont(gujaratiFont, 'Gujarati', 'normal');
        doc.setFont('Gujarati');

        // Use order_id from the first item in the list
        const orderNumber = ingredients[0].order_id || '-';
        doc.setFontSize(16);
        doc.text('Dairy Order', 105, 15, { align: 'center' });
        doc.setFontSize(12);
        doc.text(`Order No. : ${orderNumber}`, 105, 25, { align: 'center' });

        // Prepare table columns and rows
        const tableColumn = ['Ingredient', 'Meal Date', 'Litres/kgs', 'Crates'];
        const tableRows = ingredients.map(item => [
            item.ingredient || '-',
            item.date || '-',
            item.order_qty || item.liters_kgs || '-',
            item.crates_qty || '-'
        ]);

        // Generate the table using jspdf-autotable
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 35,
            styles: {
                fontSize: 10,
                cellPadding: 3,
                font: 'Gujarati'
            },
            headStyles: {
                fillColor: [41, 128, 185], // Blue header
                textColor: 255 // White text
            }
        });

        // Save the PDF
        doc.save(`dairy_order_${orderNumber}.pdf`);
    };

    const handleClose = () => {
        setShowOrderModal(false);
        clearItem();
    };

    if (!showOrderModal) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={(e) => {
                if (e.target === e.currentTarget) handleClose();
            }}
        >
            <div
                className="bg-white rounded-lg shadow-xl w-[600px] max-w-[90%] p-6"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="mb-4 text-center font-semibold">
                        Order No. : {ingredients[0].order_id}
                </div>

                <table className="w-full border-collapse mb-4">
                    <thead>
                        <tr>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Ingredient</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" rowSpan="2">Meal Date</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center" colSpan="2">Order Qty</th>
                        </tr>
                        <tr>
                            <th className="px-2 py-1 border border-black text-center">Liters/Kgs</th>
                            <th className="px-2 py-1 border border-black text-center">Crates</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ingredients.map((item, index) => (
                            <tr key={index}>
                                <td className="border border-black p-2 text-center">{item.ingredient}</td>
                                <td className="border border-black p-2 text-center">{item.date}</td>
                                <td className="border border-black p-2 text-center">{item.order_qty || '-'}</td>
                                <td className="border border-black p-2 text-center">{item.crates_qty || '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={handleClose}
                        className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300 transition-colors"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleExportPDF}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                    >
                        Export PDF
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OrderIngredientModal;